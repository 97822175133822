import { Button, Checkbox, Modal } from 'antd';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import infoDialogMessages from '../../../messages/OperationInfoDialog.messages';
import messages from './TabHeader.messages';
import theme from './TabHeader.scss';
import { TBtnProperties } from './TabHeader.types';
import { showDeleteConfirmation } from '../../../../Button/DeleteButton';
import { LocalesService } from '../../../../../../services/LocalesService';
import { SearchInput } from '../../../../../UIKit/components/Select/SearchInput.component';
import { TImportedImg } from '../../../../../../utils/types';

type TTabHeaderProps = {
    buttons?: Array<TBtnProperties>;
    inputDataTest?: string;
    customButtons?: JSX.Element;
    onSearchChange?: (value: string) => void;
};

type TTabHeaderFullProps = TTabHeaderProps & WrappedComponentProps;

enum ButtonKey {
    DELETE = 'DELETE',
    MOVE = 'MOVE',
    EXPORT = 'EXPORT',
    IMPORT = 'IMPORT',
    ADD = 'ADD'
}

export class ExportButton {
    static build(onAction: () => void, name: any, disabled?: boolean, blocked?: boolean) {
        const intl = LocalesService.useIntl();

        return {
            key: ButtonKey.EXPORT + uuid(),
            onAction: () => {
                if (blocked) {
                    Modal.info({ title: intl.formatMessage(infoDialogMessages.deny) });
                } else {
                    onAction();
                }
            },
            disabled,
            name,
        };
    }
}

export class ImportButton {
    static build(onAction: () => void, name: any, disabled?: boolean, blocked?: boolean, icon?: TImportedImg) {
        const intl = LocalesService.useIntl();

        return {
            key: ButtonKey.IMPORT + uuid(),
            onAction: () => {
                if (blocked) {
                    Modal.info({ title: intl.formatMessage(infoDialogMessages.deny) });
                } else {
                    onAction();
                }
            },
            disabled,
            name,
            icon,
        };
    }
}

export class MoveSelected {
    static build(onAction: () => void, disabled?: boolean, key?: string) {
        return {
            key: key || ButtonKey.MOVE + uuid(),
            onAction,
            disabled,
            name: messages.moveSelected,
        };
    }
}

export class DeleteSelected {
    static build(onAction: () => void, disabled?: boolean, key?: string, title?: string, dialogContent?: React.ReactNode) {
        return {
            key: key || ButtonKey.DELETE + uuid(),
            onAction: () =>
                showDeleteConfirmation({
                    onDelete: onAction,
                    deleteQuestion: title,
                    dialogContent,
                }),
            disabled,
            size: 'small',
            type: 'ghost',
            name: messages.deleteSelected,
        };
    }
}

export class AddModel {
    static build(onAction: () => void, disabled?: boolean, key?: string) {
        return {
            key: key || ButtonKey.ADD + uuid(),
            onAction,
            disabled,
            name: messages.addModelType,
        };
    }
}

const TabHeader = (props: TTabHeaderFullProps) => {
    const { buttons: allButtons = [], inputDataTest, customButtons, onSearchChange } = props;
    const checkbox = allButtons.filter((button) => button.isCheckbox);
    const buttons = allButtons.filter((button) => !button.isCheckbox);
    const [searchValue, setSearchValue] = useState<string>('');

    return (
        <div className={theme.searchDiv}>
            <div className={theme.searchInput} data-test={inputDataTest}>
                
                {onSearchChange && (
                    <SearchInput
                        originalTheme
                        showSearch={!!onSearchChange}
                        searchValue={searchValue}
                        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onSearchChange(e.target.value);
                            setSearchValue(e.target.value);
                        }}
                    />
                )}
            </div>
            <div className={theme.actionButtonsCheckbox}>
                {checkbox.map((button) => (
                    <Checkbox
                        key={button.key || props.intl.formatMessage(button.name)}
                        checked={button.checked}
                        onChange={button.onAction}
                    >
                        {props.intl.formatMessage(button.name)}
                    </Checkbox>
                ))}
            </div>
            <div className={theme.actionButtons}>
                {buttons.map((button) => (
                    <Button
                        key={button.key || props.intl.formatMessage(button.name)}
                        size={button.size || 'small'}
                        type={button.type || 'ghost'}
                        onClick={button.onAction}
                        disabled={button.disabled}
                        data-test={button.dataTestId}
                        className={theme.button}
                    >
                        <div className={theme.buttonAnnotation}>
                            {button.icon && <Icon spriteSymbol={button.icon} disabled={button.disabled} />}
                            <div>{props.intl.formatMessage(button.name)}</div>
                        </div>
                    </Button>
                ))}
                {customButtons}
            </div>
        </div>
    );
};

const TabHeaderIntl = injectIntl(TabHeader);

export { TabHeaderIntl as TabHeader };
