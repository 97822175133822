import { Comment, EntityStringRequest } from '@/serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle } from '../api/api-bundle';

export class CommentsDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    };

    public static async pinComment(repositoryId: string, commentId: string): Promise<Comment> {
        const api = this.getApi();
        const value = true;
        const comment: Comment = await api.comment.pin({
            repositoryId,
            commentId,
            body: { value },
        });

        return comment;
    }

    public static async unpinComment(repositoryId: string, commentId: string): Promise<Comment> {
        const api = this.getApi();
        const value = false;
        const comment: Comment = await api.comment.pin({
            repositoryId,
            commentId,
            body: { value },
        });

        return comment;
    }

    public static async setCritical(repositoryId: string, commentId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.additionalStatus({
            repositoryId,
            commentId,
            body: 'CRITICAL',
        });

        return comment;
    }

    public static async setUncritical(repositoryId: string, commentId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.additionalStatus({
            repositoryId,
            commentId,
            body: 'EMPTY',
        });

        return comment;
    }

    public static async setOpen(repositoryId: string, commentId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.status({
            repositoryId,
            commentId,
            body: 'OPEN',
        });

        return comment;
    }

    public static async setClose(repositoryId: string, commentId: string): Promise<Comment> {
        const api = this.getApi();

        const comment: Comment = await api.comment.status({
            repositoryId,
            commentId,
            body: 'CLOSE',
        });

        return comment;
    }

    public static async uploadCommentFile(
        repositoryId: string,
        commentId: string,
        body: File,
    ): Promise<EntityStringRequest> {
        const api = this.getApi();

        const res: EntityStringRequest = await api.comment.uploadCommentFile({
            repositoryId,
            commentId,
            body
        });

        return res;
    }

    public static async deleteCommentFile(
        repositoryId: string,
        commentId: string,
        fileId: string,
    ): Promise<void> {
        const api = this.getApi();

        await api.comment.deleteCommentFile({
            repositoryId, commentId, fileId
        });
    }

    public static async save(comment: Comment): Promise<Comment> {
        const api = this.getApi();

        const newComment = await api.comment.save({ body: comment });

        return { ...newComment, commentId: { ...newComment.commentId, serverId: comment?.commentId.serverId } };
    }
}
