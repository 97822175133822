import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ModelType } from '../../../../../../serverapi/api';
import { Dialog } from '../../../../../UIKit/components/Dialog/Dialog.component';
import messages from './ObjectType.messages';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import icAttribute from '../../../../../../resources/icons/ic-attribute.svg';
import theme from './AddModelTypeDialog.scss';
import { TTableDataType, TTableRowRecord } from '../util/GroupedTypesTable.types';
import { Button } from 'antd';
import { SearchInput } from '../../../../../UIKit/components/Select/SearchInput.component';
import { uniqBy } from 'lodash-es';

type TAddModelTypeDialogIntlProps = {
    modelTypes: ModelType[];
    dialogVisible: boolean;
};

type TAddModelTypeDialogIntlActionProps = {
    onOk: (modelTypes: ModelType[]) => void;
    onCancel: () => void;
};

type TAddModelTypeDialogIntlFullProps = TAddModelTypeDialogIntlProps &
    TAddModelTypeDialogIntlActionProps &
    WrappedComponentProps;

const AddModelTypeDialogComponent = (props: TAddModelTypeDialogIntlFullProps) => {
    const [newModelTypes, setNewModelTypes] = useState<ModelType[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchFilter(e.target.value);
    }

    const modelTypeGroups = uniqBy(
        props.modelTypes.map((mt) => mt.modelTypeGroup),
        'id',
    );

    const footer = [
        <Button className={theme.cancelButton} key="cancel" size="large" onClick={() => props.onCancel()}>
            <div data-test="new-model-type-window_cancel-button">{props.intl.formatMessage(messages.cancel)}</div>
        </Button>,
        <Button
            className={theme.okButton}
            key="ok"
            size="large"
            type="primary"
            onClick={() => props.onOk(newModelTypes)}
        >
            <div data-test="new-model-type-window_save-button">{props.intl.formatMessage(messages.save)}</div>
        </Button>,
    ];

    const rowClassName = (record: TTableRowRecord<TTableDataType>) => {
        const isGroup = modelTypeGroups.find((modelTypeGroup) => modelTypeGroup.id === record.id);

        return isGroup ? '' : theme.rowChild;
    };

    return (
        <div>
            <Dialog
                className={theme.dialog}
                onCancel={props.onCancel}
                open={props.dialogVisible}
                title={props.intl.formatMessage(messages.addModelType)}
                footer={footer}
            >
                <SearchInput showSearch originalTheme onSearch={handleSearch} searchValue={searchFilter} />
                <div className={theme.title}>{props.intl.formatMessage(messages.name)}</div>
                <GroupedTypesTable
                    types={props.modelTypes?.map((type) => ({
                        ...type,
                        groupId: type.modelTypeGroup?.id,
                    }))}
                    typeGroups={modelTypeGroups}
                    searchFilter={searchFilter}
                    actionsDisabled={false}
                    onSelectType={setNewModelTypes as any}
                    icon={icAttribute}
                    hideGUIDColumn
                    rowClassName={rowClassName}
                />
            </Dialog>
        </div>
    );
};

const AddModelTypeDialogIntl = injectIntl(AddModelTypeDialogComponent);

export { AddModelTypeDialogIntl as AddModelTypeDialog };
