import { Form, Tabs } from 'antd';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import presetsCss from '../../Presets.scss';
import presetMessages from '../../../../messages/Presets.messages';
import messages from './PresetProfileTab.messages';
import PresetProfileEditGeneralSettings from '../PresetProfileGeneralSettings/PresetProfileGeneralSettings.component';
import { IWorkspaceTabPresetProfileParams, TWorkspaceTab } from '../../../../../../../models/tab.types';
import { EditorFooterButtons } from '../../Footer/EditorFooterButtons.component';
import footerMessages from '../../Footer/EditorFooterButtons.messages';
import { workspaceRemoveTabByNodeId } from '../../../../../../../actions/tabs.actions';
import { useDispatch, useSelector } from 'react-redux';
import { copyPresetProfile, savePresetProfile } from '../../../../../../../actions/presetProfile.actions';
import { PresetProfile } from '../../../../../../../serverapi/api';
import { saveAllAcls } from '../../../../../../../actions/acl.actions';
import PresetProfileModelTypes from '../PresetProfileModelTypes/PresetProfileModelTypes.component';
import PresetProfileAttributesTypes from '../PresetProfileAttributesTypes/PresetProfileAttributesTypes.component';
import PresetProfileEdgeTypes from '../PresetProfileEdgeTypes/PresetProfileEdgeTypes.component';
import PresetProfileObjectTypes from '../PresetProfileObjectTypes/PresetProfileObjectTypes.component';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { createButtonsCompositeDataTestId } from '../../util/createButtonsCompositeDataTestId.utils';
import { TBtnProperties } from '../../Header/TabHeader.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TPresetProfileProps = {
    tab: TWorkspaceTab;
};

const PresetProfileTab: FC<TPresetProfileProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabPresetProfileParams;
    const { preset, serverNode, mode, copiedPresetProfileId } = params;
    const [presetProfile, setPresetProfile] = useState<PresetProfile>(params.presetProfile);
    const { serverId } = serverNode.nodeId;
    const intl = useIntl();
    const [generalForm] = Form.useForm();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const tabTitleSuffix = presetProfile?.multilingualName
        ? `: ${LocalesService.internationalStringToString(presetProfile?.multilingualName, currentLocale)}`
        : '';

    const isCopyMode: boolean = mode === 'copy';

    const onSaveAction = () => {
        generalForm
            .validateFields()
            .then(() => {
                if (isCopyMode) {
                    dispatch(
                        copyPresetProfile({
                            presetProfile,
                            serverNode,
                            copiedPresetProfileId,
                            presetId: preset.id,
                            tabNodeId: props.tab.nodeId,
                        }),
                    );
                } else {
                    dispatch(
                        savePresetProfile({
                            presetProfile,
                            serverNode,
                            mode,
                            tabNodeId: props.tab.nodeId,
                        }),
                    );
                    dispatch(
                        saveAllAcls({
                            serverId,
                            profileId: presetProfile.id,
                            presetId: preset.id,
                        }),
                    );
                }
            })
            .catch(() => undefined);
    };

    const footerButtons: TBtnProperties[] = [
        {
            name: intl.formatMessage(footerMessages.cancel),
            onAction: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
            dataTestId: 'cancel-button',
        },
        {
            name: intl.formatMessage(params.mode === 'create' ? footerMessages.create : footerMessages.save),
            type: 'primary',
            onAction: onSaveAction,
            dataTestId: 'save-button',
        },
    ];

    const tabsWithCopyMode: Tab[] = [
        {
            label: <span>{intl.formatMessage(presetMessages.generalSettings)}</span>,
            key: intl.formatMessage(presetMessages.generalSettings),
            children: (
                <div className={presetsCss.tabContent}>
                    <PresetProfileEditGeneralSettings
                        serverId={serverId}
                        presetId={preset.id}
                        presetProfile={presetProfile}
                        setPresetProfile={setPresetProfile}
                        generalForm={generalForm}
                        hideServerProfileSetter={isCopyMode}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(footerButtons, 'general-settings')} />
                </div>
            ),
        },
    ];

    const tabs: Tab[] = [
        {
            label: <span>{intl.formatMessage(presetMessages.modelTypesTab)}</span>,
            key: intl.formatMessage(presetMessages.modelTypesTab),
            children: (
                <div className={presetsCss.tabContent}>
                    <PresetProfileModelTypes presetId={preset.id} serverId={serverId} profileId={presetProfile.id} />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(footerButtons, 'model-types_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(presetMessages.objectTypesTab)}</span>,
            key: intl.formatMessage(presetMessages.objectTypesTab),
            children: (
                <div className={presetsCss.tabContent}>
                    <PresetProfileObjectTypes presetId={preset.id} serverId={serverId} profileId={presetProfile.id} />
                    <EditorFooterButtons
                        buttons={createButtonsCompositeDataTestId(footerButtons, 'object-types_tab')}
                    />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(presetMessages.edgeTypesTab)}</span>,
            key: intl.formatMessage(presetMessages.edgeTypesTab),
            children: (
                <div className={presetsCss.tabContent}>
                    <PresetProfileEdgeTypes presetId={preset.id} serverId={serverId} profileId={presetProfile.id} />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(footerButtons, 'edge-types_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(presetMessages.attributeTypesTab)}</span>,
            key: intl.formatMessage(presetMessages.attributeTypesTab),
            children: (
                <div className={presetsCss.tabContent}>
                    <PresetProfileAttributesTypes
                        presetId={preset.id}
                        serverId={serverId}
                        profileId={presetProfile.id}
                    />
                    <EditorFooterButtons
                        buttons={createButtonsCompositeDataTestId(footerButtons, 'attribute-types_tab')}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={presetsCss.container}>
            <span className={presetsCss.navigationTitle}>
                {`${preset.name} > ${intl.formatMessage(presetMessages.accessRights)} > ${intl.formatMessage(
                    messages[`editProfile.${mode}`],
                )}${tabTitleSuffix}`}
            </span>
            <Tabs
                className={presetsCss.tabs}
                tabPosition="left"
                items={!isCopyMode ? [...tabsWithCopyMode, ...tabs] : tabsWithCopyMode}
            />
        </div>
    );
};

export default PresetProfileTab;
