import type { TBPMMxGraphOptions } from '../bpmgraph.types';
import { EditorMode } from '@/models/editorMode';
import { MxPopupMenu, MxCell, MxUtils } from '../mxgraph';
import { DefaultGraph } from '../DefaultGraph';
import { GridDiagram } from './grid/GridDiagram';
import { GridLayout, Symbol } from '@/serverapi/api';
import { BPMMxVertexHandler } from '../handler/BPMMxVertexHandler.class';
import { BPMMxGraphHandler } from '../handler/BPMMxGraphHandler';
import GridModelGraphHandler from './handlers/GridModelGraphHandler.class';
import { GridModelVertexHandler } from './handlers/GridModelVertexHadler.class';
import { showGridNotification } from './grid/SideEffects';
import { NotificationType } from '@/models/notificationType';
import { defaultGridLayout } from './grid/DefaultGridDIagram.config';

const minTableColumnWidth = 20;

export default class GridModelGraph extends DefaultGraph {
    protected grid: GridDiagram;
    protected resizeHeadersOnly = false;
    isHightLightDropUnavailableCells = true;
    isResizeHeadersOnly = false;

    constructor(options: TBPMMxGraphOptions & { mode?: EditorMode } = {}) {
        super(options);

        const name = this.modelType?.name;
        this.createGrid(options.gridLayout, name);
    }

    protected createGrid(gridLayout: GridLayout = defaultGridLayout, name) {
        this.grid = new GridDiagram(this, gridLayout, name);
    }

    moveCells(
        cells: MxCell[],
        dx: number,
        dy: number,
        clone: boolean,
        target?: MxCell | null,
        evt?: Event,
        mapping?: any,
    ): MxCell[] {
        if (!target) {
            return [];
        }

        const [objectCell] = cells.filter(this.isSupportedCell) || [];

        if (this.grid && !this.grid.isGridValidTarget(cells, target, objectCell?.getValue()?.symbolId)) {
            return [];
        }

        const result = super.moveCells(cells, dx, dy, clone, target, evt, mapping);

        if (!objectCell) {
            return result;
        }

        return result;
    }

    dropFailedHandler(target: MxCell, symbol: Symbol) {
        showGridNotification(NotificationType.PSD_DROP_NOT_SUPPORTED);
    }

    getDropTarget(cells: MxCell[] = [], evt: MouseEvent, cell: MxCell, clone?: boolean) {
        const target = super.getDropTarget.apply(this, arguments);
        const objectCells = cells.filter(this.isSupportedCell) || [];

        const isAvailable = !objectCells.find((objectCell) => {
            return !this.isDropAvailable(cells, target, objectCell?.getValue()?.symbolId);
        });

        if (isAvailable) {
            return target;
        }
    }

    public isDropAvailable(cells: MxCell[], target: MxCell, symbolId: string) {
        return this.grid.isGridValidTarget(cells, target, symbolId);
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell, disabled: boolean = false) {
        super.loadPopupMenu(menu, cell);
        this.grid.loadPopupMenu(menu, cell, disabled);
    }
    public serializeGrid(): GridLayout {
        return this.grid.serialize();
    }

    protected isSupportedCell(cell: MxCell): boolean {
        return cell.getValue()?.type === 'object';
    }
    public createVertexHandler(state: any): BPMMxVertexHandler {
        return new GridModelVertexHandler(state);
    }
    public createGraphHandler(): BPMMxGraphHandler {
        return new GridModelGraphHandler(this);
    }
    public insertTableRow(cell, before = false): MxCell | null {
        const model = this.getModel();
        model.beginUpdate();

        try {
            let table = cell;
            let row = cell;

            if (this.isTableCell(cell)) {
                row = model.getParent(cell);
                table = model.getParent(row);
            } else if (this.isTableRow(cell)) {
                table = model.getParent(cell);
            } else {
                let rows = model.getChildCells(table, true);
                row = rows[before ? 0 : rows.length - 1];
            }

            const cells = model.getChildCells(row, true);
            const index = table.getIndex(row);
            row = model.cloneCell(row, false);
            row.value = null;

            const rowGeo = this.getCellGeometry(row);

            if (rowGeo != null) {
                for (let i = 0; i < cells.length; i++) {
                    const cell = model.cloneCell(cells[i], false);
                    row.insert(cell);
                    cell.value = null;

                    var geo = this.getCellGeometry(cell);

                    if (geo != null) {
                        geo.height = rowGeo.height;
                    }
                }

                model.add(table, row, index + (before ? 0 : 1));

                let tableGeo = this.getCellGeometry(table);

                if (tableGeo != null) {
                    tableGeo = tableGeo.clone();
                    tableGeo.height += rowGeo.height;

                    model.setGeometry(table, tableGeo);
                }
            }
            model.endUpdate();

            return row;
        } catch (e) {
            console.error(e);
            model.endUpdate();

            return null;
        }
    }
    public insertTableColumn(cell, before): MxCell[] | null {
        const model = this.getModel();
        model.beginUpdate();

        try {
            let table = cell;
            let index = 0;

            if (this.isTableCell(cell)) {
                var row = model.getParent(cell);
                table = model.getParent(row);
                index = MxUtils.indexOf(model.getChildCells(row, true), cell);
            } else {
                if (this.isTableRow(cell)) {
                    table = model.getParent(cell);
                } else {
                    cell = model.getChildCells(table, true)[0];
                }

                if (!before) {
                    index = model.getChildCells(cell, true).length - 1;
                }
            }

            const rows = model.getChildCells(table, true);
            let dw = minTableColumnWidth;
            const columnCells: MxCell[] = [];

            for (let i = 0; i < rows.length; i++) {
                const child = model.getChildCells(rows[i], true)[index];
                const clone = model.cloneCell(child, false);
                const geo = this.getCellGeometry(clone);
                clone.value = null;

                if (geo != null) {
                    dw = geo.width;
                    const rowGeo = this.getCellGeometry(rows[i]);

                    if (rowGeo != null) {
                        geo.height = rowGeo.height;
                    }
                }

                model.add(rows[i], clone, index + (before ? 0 : 1));
                columnCells.push(clone);
            }

            let tableGeo = this.getCellGeometry(table);

            if (tableGeo != null) {
                tableGeo = tableGeo.clone();
                tableGeo.width += dw;

                model.setGeometry(table, tableGeo);
            }
            model.endUpdate();

            return columnCells;
        } catch (e) {
            console.error(e);
            model.endUpdate();

            return null;
        }
    }
}
