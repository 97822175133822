import React, {useEffect, useState} from 'react';
import { useIntl } from "react-intl";
import { Dialog } from "../../../UIKit/components/Dialog/Dialog.component";
import theme
    from "./ServerProfileChange.scss";
import { Select, Typography } from "antd";
import messages from '../../messages/ServerProfileChange.messages';
import {Icon} from "../../../UIKit/components/Icon/Icon.component";
import icWarning from "../../../../resources/icons/ic-warning.svg";
import { TServerProfileChangeProps } from '../../ServerProfile.types';
import { useDispatch } from 'react-redux';
import { updateUserServerProfiles } from '../../../../actions/serverProfile.actions';


const { Text } = Typography;

export const ServerProfileSwitcherComponent = (props: TServerProfileChangeProps) => {
    const [profileId, setProfileId] = useState<string>(props.activeServerProfileId || props.serverProfiles?.[0]?.id);
    const intl = useIntl();
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(updateUserServerProfiles(props.serverId));
    }, []);
    const warn = (
        <div className={theme.warn}>
            <Text type="warning">{intl.formatMessage(messages.warn)}</Text>
            <Icon spriteSymbol={icWarning} className={theme.warnIcon} />
        </div>
    );

    return (
        <Dialog
            open={props.visible}
            onCancel={props.onCancel}
            title={intl.formatMessage(messages.title)}
            onOk={() => props.onOk(profileId)}
            okText={intl.formatMessage(messages.ok)}
            cancelText={intl.formatMessage(messages.cancel)}
        >
            <Select
                className={theme.selectProfile}
                value={profileId}
                onChange={(id: string) =>{
                    setProfileId(id);
                }}
            >
                {props.availableUserProfiles?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                        {option.name}
                    </Select.Option>
                ))}
            </Select>
            {warn}
        </Dialog>

    );
};
