import { PrincipalsDTO, UserDTO, UserSessionHistory } from '../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ApiBundle, apiBundle } from '../api/api-bundle';

export class UsersDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async changeCurrentUserPassword(oldPassword: string, newPassword: string): Promise<void> {
        const api = this.getApi();

        return api.user.changeCurrentUserPassword({ body: { oldPassword, newPassword } });
    }

    getAccessibleRepositories(params: { serverId: string; userId: number }): Promise<string[]> {
        const { serverId, userId } = params;

        return this.getAPI(serverId).user.getUsersAccessibleRepositories({ userId });
    }

    getByAccess(params: { access: string; serverId: string }): Promise<UserDTO[]> {
        return this.getAPI(params.serverId)
            .user.getByAccess({ access: params.access })
            .then((users) => (users?.forEach((u) => this.setUserId(u)), users));
    }

    getByLicense(params: { license: string; serverId: string }): Promise<UserDTO[]> {
        return this.getAPI(params.serverId)
            .user.getByLicense({ license: params.license })
            .then((users) => (users?.forEach((u) => this.setUserId(u)), users));
    }

    get(params: { id: number; serverId: string }): Promise<UserDTO> {
        return this.getAPI(params.serverId)
            .user.get({ id: params.id })
            .then((u) => (this.setUserId(u), u));
    }

    getByGroup(params: { groupId: number; serverId: string }): Promise<UserDTO[]> {
        return this.getAPI(params.serverId)
            .group.getByGroup({ groupId: params.groupId })
            .then((users) => (users?.forEach((u) => this.setUserId(u)), users));
    }

    save(params: { user: UserDTO; serverId: string }): Promise<UserDTO> {
        return this.getAPI(params.serverId)
            .user.save({ body: params.user })
            .then((u) => (this.setUserId(u), u));
    }

    create(params: { user: UserDTO; serverId: string }): Promise<UserDTO> {
        return this.getAPI(params.serverId)
            .user.create({ body: params.user })
            .then((u) => (this.setUserId(u), u));
    }

    getAll(params: { serverId: string }): Promise<UserDTO[]> {
        return this.getAPI(params.serverId)
            .user.getAll()
            .then((users) => (users?.forEach((u) => this.setUserId(u)), users));
    }

    sessionHistory(params: { id: number; serverId: string }): Promise<UserSessionHistory[]> {
        return this.getAPI(params.serverId).session.getUserSessionsHistory({ userid: params.id });
    }

    removeByGroup(params: { groupId: number; userId: number; serverId: string }): Promise<void> {
        return this.getAPI(params.serverId).group.removeByGroup({ groupId: params.groupId, userId: params.userId });
    }

    addByGroup(params: { groupId: number; usersIds: PrincipalsDTO; serverId: string }): Promise<void> {
        return this.getAPI(params.serverId).group.addByGroup({ groupId: params.groupId, body: params.usersIds });
    }

    delete(params: { usersId: number[]; serverId: string }): Promise<void[]> {
        return Promise.all(params.usersId.map((id) => this.getAPI(params.serverId).user._delete({ id })));
    }

    async setBlockStatus(usersId: number[], blockStatus: boolean, serverId: string): Promise<UserDTO[]> {
        const currentUsersData: UserDTO[] = await Promise.all(usersId.map((id) => userService().get({ id, serverId })));

        return await Promise.all(
            currentUsersData.map((user) => userService().save({ user: { ...user, blocked: blockStatus }, serverId })),
        );
    }

    private setUserId(user: UserDTO) {
        if (!user) {
            return;
        }
        if (!isNaN(user.id)) {
            user.id = Number(user.id);
        }
        if (user.groups) {
            user.groups.forEach((group) => {
                if (!isNaN(group.id)) {
                    group.id = Number(group.id);
                }
            });
        }
    }

    private getAPI(serverId: string): ApiBundle {
        try {
            const api = apiBundle(serverId);

            return api;
        } catch (e) {
            throw new Error(`Error in UserAndGroupService, no existing API for server with id=[${serverId}]`);
        }
    }
}

let service: UsersDAOService;

export function userService(): UsersDAOService {
    if (!service) {
        service = new UsersDAOService();
    }

    return service;
}
