import { Alert, Table, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InternationalString, PresetProfile, ServerProfile } from '../../../../../../serverapi/api';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import { TabHeader } from '../Header/TabHeader.component';
import messages from './PresetProfileList.messages';
import verifiedIcon from '../../../../../../resources/icons/ic-acl-verified.svg';
import { deletePresetProfile, openEditPresetProfileTab } from '../../../../../../actions/presetProfile.actions';
import { TreeNode } from '../../../../../../models/tree.types';
import { TPreset } from '../../../../../../models/preset.types';
import { PresetProfileSelectors } from '../../../../../../selectors/presetSettings/presetProfile.selectors';
import { AlignType } from 'rc-table/lib/interface';
import theme from './PresetProfileList.scss';
import { v4 as uuid } from 'uuid';
import { ServerProfileSelectors } from '../../../../../../selectors/serverProfile.selectors';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';
import { DeleteButton, EditButton, CopyButton, RowButtons } from '../../../../Button/RowButtons';

type TPresetProfileList = {
    serverId: string;
    preset: TPreset;
    serverNode: TreeNode;
    disabled: boolean;
    onProfileDelete: (presetProfileId: string) => void;
};

const PresetProfileList: FC<TPresetProfileList> = (props) => {
    const { serverId, preset, serverNode, disabled, onProfileDelete } = props;
    const presetId = preset.id;
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const data = useSelector(PresetProfileSelectors.byPresetId({ serverId, presetId }));
    const presetProfiles: PresetProfile[] = data?.presetProfiles || [];
    const serverProfilesData = useSelector(ServerProfileSelectors.byServerId(serverId));
    const serverProfiles: ServerProfile[] = serverProfilesData.serverProfiles || [];

    const renderRowBtns = (text, presetProfile: PresetProfile) => {
        const deleteContent = (
            <Alert
                message={
                    <>
                        <b>{intl.formatMessage(messages.deleteProfile)}</b>
                        {`"${LocalesService.internationalStringToString(presetProfile.multilingualName)}"`}
                    </>
                }
                type="warning"
            />
        );

        return (
            <RowButtons
                className={theme.rowButtons}
                buttons={[
                    EditButton.build(() => {
                        dispatch(openEditPresetProfileTab({ preset, serverNode, mode: 'edit', presetProfile }));
                    }),
                    CopyButton.build(() => {
                        const clonePresetProfile = { ...presetProfile };
                        delete clonePresetProfile.serverProfileId;
                        const copyPresetProfile: PresetProfile = {
                            ...clonePresetProfile,
                            id: uuid(),
                        };
                        dispatch(
                            openEditPresetProfileTab({
                                preset,
                                serverNode,
                                mode: 'copy',
                                presetProfile: copyPresetProfile,
                                copiedPresetProfileId: presetProfile.id,
                            }),
                        );
                    }),
                    DeleteButton.build(
                        () => {
                            dispatch(deletePresetProfile({ presetId, serverId, id: presetProfile.id }));
                            onProfileDelete(presetProfile.id);
                        },
                        false,
                        undefined,
                        undefined,
                        intl.formatMessage(messages.delete),
                        deleteContent,
                    ),
                ]}
            />
        );
    };

    const columns = [
        {
            title: '',
            dataIndex: 'serverProfileId',
            key: 'serverProfileId',
            render: (value: string) => {
                const fitProfile = serverProfiles.find((sProfile) => sProfile.id === value);
                if (fitProfile) {
                    return (
                        <Tooltip title={intl.formatMessage(messages.serverProfileFinded)}>
                            <div className={theme.checkIcon}>
                                <Icon spriteSymbol={verifiedIcon} />
                            </div>
                        </Tooltip>
                    );
                }

                return null;
            },
            className: theme.serverProfileIdColumn,
            align: 'center' as AlignType,
        },
        {
            title: intl.formatMessage(messages.profileName),
            dataIndex: 'multilingualName',
            key: 'multilingualName',
            className: theme.namesColumn,
            render: (value: InternationalString) => LocalesService.internationalStringToString(value, currentLocale),
        },
        {
            title: intl.formatMessage(messages.description),
            dataIndex: 'multilingualDescription',
            key: 'multilingualDescription',
            className: theme.column,
            render: (value: InternationalString) => LocalesService.internationalStringToString(value, currentLocale),
        },
        {
            title: intl.formatMessage(messages.serverProfile),
            dataIndex: 'serverProfileId',
            key: 'serverProfileId',
            className: theme.column,
            render: (value: string) => {
                const fitProfile: ServerProfile | undefined = serverProfiles.find((sProfile) => sProfile.id === value);

                return fitProfile?.name || '';
            },
        },
        {
            title: '',
            dataIndex: 'manageBtn',
            key: 'manageBtn',
            render: renderRowBtns,
            className: theme.buttonsColumn,
        },
    ];

    return (
        <section className={theme.container}>
            <TabHeader
                buttons={[
                    {
                        name: messages.addProfile,
                        onAction: () => {
                            dispatch(
                                openEditPresetProfileTab({
                                    preset,
                                    serverNode,
                                    mode: 'create',
                                }),
                            );
                        },
                        disabled,
                    },
                ]}
            />
            <Table
                rowKey={(presetProfile: PresetProfile) => presetProfile.id}
                dataSource={presetProfiles}
                columns={columns}
                pagination={false}
                className={theme.table}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </section>
    );
};

export default PresetProfileList;
