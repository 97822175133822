import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TCommentsPanelProps, TCommentsPanelPropsOwnProps } from '../components/Comments.types';
import { CommentsPanel } from '../components/CommentsPanel.component';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import {
    closeCommentsPanel,
    setDisplayCommentMarkers,
    dragCommentMarker,
    deleteCommentMarker,
    pinComment,
    unpinComment,
    clearComments,
    deleteEditingComment,
} from '../../../actions/comments.actions';
import { Comment, NodeId } from '../../../serverapi/api';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { activeContextMenuChange } from '../../../actions/contextMenu.actions';
import { TWorkspaceTab } from '../../../models/tab.types';
import { WorkSpaceTabTypes } from '../../Workspace/WorkSpaceTabTypesEnum';
import { TComment } from '@/reducers/comments.reducer.types';

const mapStateToProps = (state: TRootState, ownProps: TCommentsPanelPropsOwnProps): Partial<TCommentsPanelProps> => {
    const scheme: TWorkspaceTab | undefined = TabsSelectors.getActiveTab(state);
    const sortedComments: Comment[] = CommentsSelectors.getSortedActualComments(ownProps.modelNodeId || ({} as NodeId))(
        state,
    );
    const editingComment: TComment | undefined = CommentsSelectors.getEditingComment(scheme?.nodeId || ({} as NodeId))(
        state,
    );

    let isEditedComment: boolean | undefined;

    if (sortedComments.length) {
        isEditedComment = sortedComments.every((c: Comment) => {
            return CommentsSelectors.isEditedComment(scheme?.nodeId || ({} as NodeId), c.commentId.id)(state);
        });
    } else {
        isEditedComment = !!editingComment?.text;
    }
    const disableFilters: boolean = scheme?.type === WorkSpaceTabTypes.WIKI_EDITOR;
    const commentsInGraph: Comment[] = scheme?.nodeId ? CommentsSelectors.getCommentsInGraph(scheme.nodeId)(state) : [];

    return {
        scheme,
        isEditedComment,
        disableFilters,
        sortedComments,
        commentsInGraph,
        getSortedActualComments: (modelId: NodeId) => {
            return CommentsSelectors.getSortedActualComments(modelId)(state);
        },
        getPinnedComments: (modelId: NodeId) => {
            return CommentsSelectors.getPinnedComments(modelId)(state);
        },
        getCommentsByParentId: (modelId: NodeId, parentId: string) => {
            return CommentsSelectors.getCommentsByParentId(modelId, parentId)(state);
        },
    };
};

const mapDispatchToProps = (dispatch): Partial<TCommentsPanelProps> => ({
    deleteComment: (modelId: NodeId, commentId: NodeId, isAnswer?: true) =>
        dispatch(openDialog(DialogType.DELETE_COMMENT_DIALOG, { modelId, commentId, isAnswer: isAnswer || false })),
    closeCommentsPanel: (modelId: NodeId) => {
        dispatch(deleteEditingComment(modelId));
        dispatch(closeCommentsPanel({ modelId }));
        dispatch(clearComments(modelId));
    },
    onCancel: (modelId: NodeId) =>
        dispatch(
            openDialog(DialogType.DELETE_EDITING_COMMENT_DIALOG, {
                onCancel: () => {
                    dispatch(closeDialog(DialogType.WRITE_COMMENT_DIALOG));
                    dispatch(closeCommentsPanel({ modelId }));
                    dispatch(clearComments(modelId));
                    dispatch(deleteEditingComment(modelId));
                },
            }),
        ),
    createMarker: (comment: Comment, isMarkerDragging?: boolean) =>
        dispatch(dragCommentMarker(comment, isMarkerDragging)),
    deleteMarker: (commentId: NodeId) => dispatch(deleteCommentMarker(commentId)),
    changeDisplayCommentMarkers: (commentIds: string[], newDisplayStatus: boolean) =>
        dispatch(setDisplayCommentMarkers(commentIds, newDisplayStatus)),
    onContextMenuShow: () => dispatch(activeContextMenuChange()), // todo: на кружочке больше нет контекстного меню
    pinComment: (modelId: NodeId, comment: Comment) => dispatch(pinComment(modelId, comment)),
    unpinComment: (modelId: NodeId, comment: Comment) => {
        dispatch(unpinComment(modelId, comment));
    },
});

export const CommentsPanelContainer = connect(mapStateToProps, mapDispatchToProps)(CommentsPanel);
