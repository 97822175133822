export enum ModelTypes {
    ORG_CHART = 'orgChart',
    MIND_MAP = 'mindMap',
    PSD_CHART = 'psdChart', //deprecated
    PSD_MODEL = 'MT_PRCS_SLCT_DIA',
    EPC_MODEL = 'MT_EEPC_ROW',
    SEQUENCE_DIAGRAM = 'm_uml_sequence_diagram',
}

export enum GeneralModelTypeDiscriminator {
    MODEL_TYPE = 'MODEL_TYPE',
    KANBAN = 'KANBAN',
}
