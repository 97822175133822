import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form, Tabs } from 'antd';
import theme from '../FolderType.scss';
import messages from '../FolderTypeTab.messages';
import modelTypeMessages from '../../../../../../../models/modelType.messages';
import icModel from '../../../../../../../resources/icons/ic-tree-model-active.svg';
import icObject from '../../../../../../../resources/icons/object.svg';
import icSimulationModel from '../../../../../../../resources/icons/ic-tree-im.svg';
import icFile from '../../../../../../../resources/icons/file.svg';
import icSpreadsheet from '../../../../../../../resources/icons/ic-spreadsheet.svg';
import footerMessages from '../../Footer/EditorFooterButtons.messages';
import { EditorFooterButtons } from '../../Footer/EditorFooterButtons.component';
import methodologyMessages from '../../../../messages/MethodologySetting.messages';
import {
    AttributeType,
    AttributeTypeGroup,
    FolderType,
    FolderTypeValidNodeTypesEnum,
    ModelType,
    ModelTypeGroup,
    ObjectType,
    ObjectTypeGroup,
    PresetImage,
} from '../../../../../../../serverapi/api';
import { FolderTypeEditorGeneralTab } from './Tabs/FolderTypeEditorGeneralTab.component';
import { workspaceRemoveTabByNodeId } from '../../../../../../../actions/tabs.actions';
import { PresetSettingsModelTypeSelectors } from '../../../../../../../selectors/presetSettings/presetSettingsModelType.selectors';
import { ModelTypeGroupSelectors } from '../../../../../../../selectors/modelTypeGroup.selectors';
import { ObjectTypeSelectors } from '../../../../../../../selectors/objectType.selectors';
import { ObjectTypeGroupSelectors } from '../../../../../../../selectors/objectTypeGroup.selectors';
import { FolderTypeSelectors } from '../../../../../../../selectors/folderType.selectors';
import { TreeItemType } from '../../../../../../Tree/models/tree';
import { saveFolderType } from '../../../../../../../actions/folderType.actions';
import { TNodeType } from '../FolderTypeTab.types';
import { folderTypeEditorStateSelector } from '../../../../../../../selectors/folderTypeEditor.selectors';
import {
    folderTypeEditorCancel,
    folderTypeEditorEditValue,
} from '../../../../../../../actions/folderTypeEditor.actions';
import { IWorkspaceTabItemFolderTypeEditorParams, TWorkspaceTab } from '../../../../../../../models/tab.types';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { PresetImageSelectors } from '../../../../../../../selectors/presetSettings/presetImage.selectors';
import { GroupedDataTab } from '../../common/GroupedDataTab.component';
import { FolderTypesTab } from '../../common/FolderTypesTab.component';
import { DataTypesTab } from '../../common/DataTypesTab.component';
import { createButtonsCompositeDataTestId } from '../../util/createButtonsCompositeDataTestId.utils';
import { TBtnProperties } from '../../Header/TabHeader.types';
import type { Tab } from 'rc-tabs/lib/interface';
import { AttributesTab } from '../../AttributeType/AttributesTab.component';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';

type TFolderTypeEditorTabProps = {
    tab: TWorkspaceTab;
    presetImages: PresetImage[];
};

type TValidTypes = Pick<FolderType, 'validModelTypes' | 'validFolderTypes' | 'validNodeTypes' | 'validObjectTypes'>;

type TAllowingTypes = Pick<
    FolderType,
    'allowAnyModelType' | 'allowAnyFolderType' | 'allowAnyNodeType' | 'allowAnyObjectType'
>;

export const FolderTypeEditorTab: FC<TFolderTypeEditorTabProps> = ({ tab }) => {
    const params = tab.params as IWorkspaceTabItemFolderTypeEditorParams;
    const { preset, serverNode, createMode, nodeId, folderType } = params;

    const intl = useIntl();
    const dispatch = useDispatch();
    const [generalForm] = Form.useForm();
    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;

    const [attributeTypes, setAttributeTypes] = useState<AttributeType[]>(folderType.attributeTypes);
    const availableAttributeTypes: AttributeType[] =
        useSelector(AttributeTypeSelectors.allInPreset(serverId, preset.id)) || [];

    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));
    // ModelTypes
    const modelTypes: ModelType[] = useSelector(PresetSettingsModelTypeSelectors.listByPresetId(serverId, presetId));
    const modelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );
    // ObjectTypes
    const objectTypes: ObjectType[] = useSelector(ObjectTypeSelectors.listAllByPreset(serverId, presetId));
    const objectTypesWithIds = objectTypes.map((type) => ({
        ...type,
        groupId: type.objectTypeGroup?.id,
    }));
    const objectTypeGroups: ObjectTypeGroup[] = useSelector(
        ObjectTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );
    // FolderTypes
    const folderTypes: FolderType[] = useSelector(FolderTypeSelectors.listByPresetId({ serverId, presetId }));
    const editableFolderType: FolderType = useSelector(folderTypeEditorStateSelector);
    // NodeTypes
    const file = {
        name: intl.formatMessage(modelTypeMessages.FILE),
        id: TreeItemType.File as FolderTypeValidNodeTypesEnum,
        icon: icFile,
    };
    const simulationModel = {
        name: intl.formatMessage(modelTypeMessages.SIMULATION),
        id: TreeItemType.SimulationModeling as FolderTypeValidNodeTypesEnum,
        icon: icSimulationModel,
    };
    const spreadsheet = {
        name: intl.formatMessage(modelTypeMessages.SPREADSHEET),
        id: TreeItemType.Spreadsheet as FolderTypeValidNodeTypesEnum,
        icon: icSpreadsheet,
    };
    const nodeTypes: TNodeType[] = [file, simulationModel, spreadsheet];

    function getValidTypeSetter(validTypeKey: keyof TValidTypes) {
        return (validTypeValue: string[]) => {
            dispatch(folderTypeEditorEditValue({ [validTypeKey]: validTypeValue }));
        };
    }

    function getAllowTypeSetter(allowedTypeKey: keyof TAllowingTypes) {
        return (allowedTypeValue: boolean) => {
            dispatch(folderTypeEditorEditValue({ [allowedTypeKey]: allowedTypeValue }));
        };
    }

    function handleSave() {
        dispatch(
            saveFolderType({
                nodeId,
                folderType: { ...editableFolderType, attributeTypes },
                presetId,
                createMode,
            }),
        );
    }

    const addAttributeTypes = (addedAttributeTypes: AttributeType[]) => {
        setAttributeTypes([...attributeTypes, ...addedAttributeTypes]);
    };

    const deleteAttributeTypes = (deletedAttributeTypes: AttributeType[]) => {
        setAttributeTypes(
            attributeTypes.filter(
                (attrType) => !deletedAttributeTypes.some((deletedAttrType) => attrType.id === deletedAttrType.id),
            ),
        );
    };

    const deleteAttributeTypeGroups = (attributeTypeGroups: AttributeTypeGroup[]) => {
        setAttributeTypes(
            attributeTypes.filter(
                (attrType) =>
                    !attributeTypeGroups.some((attrTypeGroup) => attrType.attributeTypeGroup?.id === attrTypeGroup.id),
            ),
        );
    };

    const buttons: TBtnProperties[] = [
        {
            name: intl.formatMessage(footerMessages.cancel),
            onAction: () => {
                dispatch(workspaceRemoveTabByNodeId(tab.nodeId));
                dispatch(folderTypeEditorCancel());
            },
            dataTestId: 'methodology-folder-type-editor_cancel-btn',
        },
        {
            name: intl.formatMessage(createMode ? footerMessages.create : footerMessages.save),
            onAction: () => {
                generalForm
                    .validateFields()
                    .then(() => {
                        handleSave();
                    })
                    .catch(() => undefined);
            },
            dataTestId: 'methodology-folder-type-editor_save-btn',
            type: 'primary',
        },
    ];

    const items: Tab[] = [
        {
            label: <span>{intl.formatMessage(messages.generalSettings)}</span>,
            key: 'GeneralSettings',
            children: (
                <div className={theme.tabContent}>
                    <FolderTypeEditorGeneralTab
                        allowApprovals={editableFolderType.allowApprovals}
                        imageId={editableFolderType.graphical}
                        presetImages={presetImages}
                        multilingualName={editableFolderType.multilingualName}
                        multilingualDescription={editableFolderType.multilingualDescription}
                        generalForm={generalForm}
                        folderTypeId={folderType.id}
                        createMode={createMode}
                        synonymsIds={editableFolderType.synonymsIds}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.modelTypes)}</span>,
            key: 'ModelTypes',
            children: (
                <div className={theme.tabContent}>
                    <GroupedDataTab
                        dataTypes={modelTypes}
                        dataTypeGroups={modelTypeGroups}
                        validTypes={editableFolderType.validModelTypes || []}
                        setValidTypes={getValidTypeSetter('validModelTypes')}
                        icon={icModel}
                        isAllowAny={!!editableFolderType.allowAnyModelType}
                        setIsAllowAny={getAllowTypeSetter('allowAnyModelType')}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'model-types_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.objectTypes)}</span>,
            key: 'ObjectTypes',
            children: (
                <div className={theme.tabContent}>
                    <GroupedDataTab
                        dataTypes={objectTypesWithIds}
                        dataTypeGroups={objectTypeGroups}
                        validTypes={editableFolderType.validObjectTypes || []}
                        setValidTypes={getValidTypeSetter('validObjectTypes')}
                        icon={icObject}
                        isAllowAny={!!editableFolderType.allowAnyObjectType}
                        setIsAllowAny={getAllowTypeSetter('allowAnyObjectType')}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'object-settings_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.folderTypes)}</span>,
            key: 'folderTypes',
            children: (
                <div className={theme.tabContent}>
                    <FolderTypesTab
                        serverId={serverId}
                        presetId={presetId}
                        folderTypes={folderTypes}
                        validTypes={editableFolderType.validFolderTypes || []}
                        setValidFolderTypes={getValidTypeSetter('validFolderTypes')}
                        isAllowAny={!!editableFolderType.allowAnyFolderType}
                        setIsAllowAny={getAllowTypeSetter('allowAnyFolderType')}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'folder-settings_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.elements)}</span>,
            key: 'elements',
            children: (
                <div className={theme.tabContent}>
                    <DataTypesTab
                        dataTypes={nodeTypes}
                        validTypes={editableFolderType.validNodeTypes || []}
                        setValidTypes={getValidTypeSetter('validNodeTypes')}
                        isAllowAny={!!editableFolderType.allowAnyNodeType}
                        setIsAllowAny={getAllowTypeSetter('allowAnyNodeType')}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'elements_tab')} />
                </div>
            ),
        },
        {
            label: <span>{intl.formatMessage(messages.attributes)}</span>,
            key: 'attributes',
            children: (
                <div className={theme.tabContent}>
                    <AttributesTab
                        attributeTypes={attributeTypes}
                        availableAttributeTypes={availableAttributeTypes.filter(
                            (availableAttrType) =>
                                !attributeTypes.some((attrType) => attrType.id === availableAttrType.id),
                        )}
                        addAttributeTypes={addAttributeTypes}
                        deleteAttributeTypes={deleteAttributeTypes}
                        deleteAttributeTypeGroups={deleteAttributeTypeGroups}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'folder_attributes_tab')} />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <span className={theme.navigationTitle}>
                {intl.formatMessage(messages.breadCrumbs, {
                    presetName: preset.name,
                    folderType: intl.formatMessage(messages.folderType),
                    folderAction: createMode
                        ? intl.formatMessage(methodologyMessages.createFolderType)
                        : LocalesService.internationalStringToString(folderType.multilingualName),
                })}
            </span>
            <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
        </div>
    );
};
