import type { Selection } from '@tiptap/pm/state';
import type { Node } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/react';
import { isNodeActive } from '@tiptap/react';
import { isNodeSelection, isTextSelection } from '@tiptap/core';
import { IMAGE_NODE_NAME } from '../Editor/extensions/constants';

export const isTableSelected = (editor: Editor) => isNodeActive(editor?.view?.state, 'table');

export const isImageSelected = (editor: Editor) => isNodeActive(editor?.view?.state, IMAGE_NODE_NAME);

export const getSelectedText = (editor: Editor) => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;

    return state.doc.textBetween(from, to, '');
};

export const hasSelectedText = (editor: Editor) => {
    return !editor.view.state.selection.empty;
};

export const getSelectedNode = (selection: Selection): Node | null =>
    (isTextSelection(selection) && selection.$head.parent) || (isNodeSelection(selection) && selection.node) || null;
