import type {
    AttributeType,
    EdgeType,
    ModelEdgeDefinition,
    ModelType,
    ObjectType,
    Symbol,
} from '../../../../../../../serverapi/api';
import { some } from 'lodash-es';
import { ComplexSymbolManager } from '../../../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { SymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';

export const checkPoolsHaveLanes = (modelTypeSymbols: Symbol[]): boolean => {
    const symbolsHaveHPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.HORIZONTAL_POOL,
    );
    const symbolsHaveVPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.VERTICAL_POOL,
    );
    const symbolsHaveVLane = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.VERTICAL_SWIMLANE,
    );
    const symbolsHaveHLane = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.HORIZONTAL_SWIMLANE,
    );
    const symbolsHaveCrossPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === SymbolTypeId.CROSS,
    );

    if (symbolsHaveHPool && !symbolsHaveHLane) {
        return false;
    }

    if (symbolsHaveVPool && !symbolsHaveVLane) {
        return false;
    }

    if (symbolsHaveCrossPool && (!symbolsHaveVLane || !symbolsHaveHLane)) {
        return false;
    }

    return true;
};

export const checkIsDoubleEdgeDefinition = (
    modelTypeEdgeDefinitions: ModelEdgeDefinition[],
    addedEdgeDefinition: ModelEdgeDefinition,
): boolean => {
    const paramEdgeDefinition = {
        edgeType: addedEdgeDefinition.edgeType,
        modelTypeId: addedEdgeDefinition.modelTypeId,
        source: addedEdgeDefinition.source,
        destination: addedEdgeDefinition.destination,
        anySourceAllowed: addedEdgeDefinition.anySourceAllowed,
        anyTargetAllowed: addedEdgeDefinition.anyTargetAllowed,
    };

    return some(modelTypeEdgeDefinitions, paramEdgeDefinition);
};

export const getObjectAvailableAttributeTypes = (
    availableAttributes: AttributeType[],
    objectTypeId: string,
    modelType: ModelType,
) => {
    const objectType: ObjectType | undefined = modelType.objectTypes.find((el) => el.id === objectTypeId);
    const definitionAttributes: AttributeType[] = availableAttributes.filter((el) =>
        objectType?.nodeAttributes.some((nodeAttr) => nodeAttr.id === el.id),
    );
    const instanceAttributes: AttributeType[] = availableAttributes.filter((el) =>
        objectType?.diagramElementAttributes.some((instAttr) => instAttr.id === el.id),
    );

    return { definitionAttributes, instanceAttributes };
};

export const getEdgeAvailableAttributeTypes = (availableAttributes: AttributeType[], edgeType: EdgeType) => {
    const definitionAttributes: AttributeType[] = availableAttributes.filter((el) =>
        edgeType?.attributeTypes.some((attrTypes) => attrTypes.id === el.id),
    );
    const instanceAttributes: AttributeType[] = availableAttributes.filter((el) =>
        edgeType?.diagramElementAttributes.some((instAttr) => instAttr.id === el.id),
    );

    return { definitionAttributes, instanceAttributes };
};
