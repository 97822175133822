import { Button, Tooltip } from 'antd';
import * as React from 'react';
import theme from './MatrixEditorToolbar.scss';
import menuItemTheme from '../items/MenuItem.scss';
import messages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { injectIntl } from 'react-intl';
import icReadMode from '../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../resources/icons/ic-edit-mode.svg';
import icSettingsMatrix from '../../../../resources/icons/ic-settingmatrix.svg';
import { EditorMode } from '../../../../models/editorMode';
import { TMatrixEditorToolbarProps } from './MatrixEditorToolbar.types';
import { isNewMatrix } from '@/modules/Matrix/utils';
import { IMatrixNode } from '@/models/bpm/bpm-model-impl.types';

const MatrixEditorToolbarComponent = (props: TMatrixEditorToolbarProps) => {
    const { intl, activeWorkspaceTab, isActiveScheme, compact, editorMode, onModeChange, openSettings } = props;
    const isReadMode = activeWorkspaceTab && activeWorkspaceTab.mode === EditorMode.Read;
    const isActiveToolbar = activeWorkspaceTab && isActiveScheme;
    const editModeTooltipeTitle = isReadMode
        ? intl.formatMessage(messages.toEditMode)
        : intl.formatMessage(messages.toReadMode);

    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');

    const handleModeChange = () => {
        onModeChange(editorMode === EditorMode.Read ? EditorMode.Edit : EditorMode.Read);
    };

    return (
        <div className={className}>
            <div className={groupClassName}>
                <div className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={isActiveScheme ? editModeTooltipeTitle : ''}
                    >
                        <Button className={theme.modeButton} onClick={handleModeChange}>
                            <Icon spriteSymbol={editorMode === EditorMode.Read ? icReadMode : icEditMode} />
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className={menuItemTheme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={isActiveToolbar && !isReadMode ? intl.formatMessage(messages.openSettings) : ''}
                    >
                        <Button
                            onClick={() => openSettings(isNewMatrix(props.activeWorkspaceTab.content as IMatrixNode), props)}
                            className={theme.matrixSettingsButton}
                            disabled={isReadMode}
                        >
                            <Icon spriteSymbol={icSettingsMatrix} disabled={isReadMode} />
                        </Button>
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};

const MatrixEditorToolbarWithIntl = injectIntl(MatrixEditorToolbarComponent);

export { MatrixEditorToolbarWithIntl as MatrixEditorToolbar };
