import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import messages from '../CommonToolbar.messages';
import icBold from 'icons/toolbar/controls/ic-font-bold.svg';
import icItalic from 'icons/toolbar/controls/ic-font-italic.svg';
import icUnderline from 'icons/toolbar/controls/ic-font-underline.svg';
import icAdditionalStyle from 'icons/toolbar/controls/ic-additional-style.svg';
import icStrikeThrough from 'icons/toolbar/controls/ic-font-strikethrough.svg';
import icSubscript from 'icons/toolbar/controls/ic-font-subscript.svg';
import icSuperscript from 'icons/toolbar/controls/ic-font-superscript.svg';
import icMonospace from 'icons/toolbar/controls/ic-font-monospace.svg';
import { useIntl } from 'react-intl';
import { Button } from '../../../Toolbar/Button.component';
import { Dropdown } from '../../../Toolbar/Dropdown.component';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';

type TInlineStyleComponent = {
    hideMoreStyles?: boolean;
};

type TInlineStyleValue = {
    [key: string]: boolean;
};

const getFocusedBlock = (editor: Editor) => ({
    BOLD: editor.isActive('bold'),
    ITALIC: editor.isActive('italic'),
    UNDERLINE: editor.isActive('underline'),
    STRIKETHROUGH: editor.isActive('strike'),
    SUPERSCRIPT: editor.isActive('superscript'),
    SUBSCRIPT: editor.isActive('subscript'),
    MONOSPACE: editor.isActive('code'),
});

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'BOLD':
            return editor.chain().focus().toggleBold().run();
        case 'ITALIC':
            return editor.chain().focus().toggleItalic().run();
        case 'UNDERLINE':
            return editor.chain().focus().toggleUnderline().run();
        case 'STRIKETHROUGH':
            return editor.chain().focus().toggleStrike().run();
        case 'SUPERSCRIPT':
            return editor.chain().focus().toggleSuperscript().unsetSubscript().run();
        case 'SUBSCRIPT':
            return editor.chain().focus().toggleSubscript().unsetSuperscript().run();
        case 'MONOSPACE':
            return editor.chain().focus().toggleCode().run();
        default:
            return false;
    }
};

export const InlineStyleComponent: FC<TInlineStyleComponent> = ({ hideMoreStyles }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<TInlineStyleValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const onChange = (activeActionId: string) => () => {
        setCurrentState(activeActionId);
    };

    const menuItems = [
        {
            value: 'STRIKETHROUGH',
            tooltip: intl.formatMessage(messages.textStrikeThrough),
            spriteSymbol: icStrikeThrough,
            selected: !!value?.STRIKETHROUGH,
            dataTest: 'wiki-toolbar-group_font-inline-style_strikethrough',
        },
        {
            value: 'SUPERSCRIPT',
            tooltip: intl.formatMessage(messages.superscript),
            spriteSymbol: icSuperscript,
            selected: !!value?.SUPERSCRIPT,
            dataTest: 'wiki-toolbar-group_font-inline-style_superscript',
        },
        {
            value: 'SUBSCRIPT',
            tooltip: intl.formatMessage(messages.subscript),
            spriteSymbol: icSubscript,
            selected: !!value?.SUBSCRIPT,
            dataTest: 'wiki-toolbar-group_font-inline-style_subscript',
        },
        {
            value: 'MONOSPACE',
            tooltip: intl.formatMessage(messages.monospace),
            spriteSymbol: icMonospace,
            selected: !!value?.MONOSPACE,
            dataTest: 'wiki-toolbar-group_font-inline-style_monospace',
        },
    ];

    return (
        <>
            <Button
                tooltipTitle={intl.formatMessage(messages.textBold)}
                icon={icBold}
                onClick={onChange('BOLD')}
                selected={!!value?.BOLD}
                dataTest="wiki-toolbar-group_font-inline-style_BOLD"
            />
            <Button
                tooltipTitle={intl.formatMessage(messages.textItalic)}
                icon={icItalic}
                onClick={onChange('ITALIC')}
                selected={!!value?.ITALIC}
                dataTest="wiki-toolbar-group_font-inline-style_ITALIC"
            />
            <Button
                tooltipTitle={intl.formatMessage(messages.textUnderline)}
                icon={icUnderline}
                onClick={onChange('UNDERLINE')}
                selected={!!value?.UNDERLINE}
                dataTest="wiki-toolbar-group_font-inline-style_UNDERLINE"
            />
            {!hideMoreStyles && (
                <Dropdown<string>
                    title={intl.formatMessage(messages.moreInlineStyles)}
                    compact={true}
                    defaultSprite={icAdditionalStyle}
                    items={menuItems}
                    onSelect={(val) => onChange(val)()}
                    dataTest="wiki-toolbar-group_font-inline-style-button_popover"
                />
            )}
        </>
    );
};
