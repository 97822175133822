import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { TCommentsPanelProps } from '../components/Comments.types';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import {
    closeCommentsPanel,
    setDisplayCommentMarkers,
    deleteCommentMarker,
    pinComment,
    unpinComment,
    clearComments,
} from '../../../actions/comments.actions';
import { Comment, NodeId } from '../../../serverapi/api';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TWorkspaceTab } from '../../../models/tab.types';
import CommentPopover from '../components/CommentPopover/CommentPopover.component';
import { activeContextMenuChange } from '@/actions/contextMenu.actions';

const mapStateToProps = (state: TRootState, own) => {
    const scheme: TWorkspaceTab | undefined = TabsSelectors.getActiveTab(state);

    return {
        scheme,
        getSortedActualComments: (modelId: NodeId) => {
            return CommentsSelectors.getSortedActualComments(modelId)(state);
        },
        getPinnedComments: (modelId: NodeId) => {
            return CommentsSelectors.getPinnedComments(modelId)(state);
        },
        getCommentsByParentId: (modelId: NodeId, parentId: string) => {
            return CommentsSelectors.getCommentsByParentId(modelId, parentId)(state);
        },
    };
};

const mapDispatchToProps = (dispatch, ownProps): Partial<TCommentsPanelProps> => ({
    deleteComment: (modelId: NodeId, commentId: NodeId, isAnswer?: true) =>
        dispatch(openDialog(DialogType.DELETE_COMMENT_DIALOG, { modelId, commentId, isAnswer: isAnswer || false })),
    closeCommentsPanel: (modelId: NodeId) => {
        dispatch(closeCommentsPanel({ modelId }));
        dispatch(clearComments(modelId));
    },
    deleteMarker: (commentId: NodeId) => dispatch(deleteCommentMarker(commentId)),
    changeDisplayCommentMarkers: (commentIds: string[], newDisplayStatus: boolean) =>
        dispatch(setDisplayCommentMarkers(commentIds, newDisplayStatus)),
    onContextMenuShow: () => dispatch(activeContextMenuChange()), // todo: на кружочке больше нет контекстного меню
    pinComment: (modelId: NodeId, comment: Comment) => dispatch(pinComment(modelId, comment)),
    unpinComment: (modelId: NodeId, comment: Comment) => {
        dispatch(unpinComment(modelId, comment));
    },
});

export const CommentPopoverContainer = connect(mapStateToProps, mapDispatchToProps)(CommentPopover);
