import type { AttributeType, ModelType, Symbol } from '../../../../../../../serverapi/api';
import type { TreeNode } from '../../../../../../../models/tree.types';
import type { TPreset } from '../../../../../../../models/preset.types';
import React, { useCallback, useState } from 'react';
import { DeleteButton, EditButton, RowButtons, SettingsButton } from '../../../../../Button/RowButtons';
import { DeleteSelected, TabHeader } from '../../Header/TabHeader.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import { contains } from '../../util/preset.utils';
import { SymbolsTabDialog } from '../Dialogs/SymbolsTabDialog.component';
import messages from '../ModelType.messages';
import { DndSortTable } from './DndSortTable.component';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { useIntl } from 'react-intl';
import { toggleArrayItem } from '../../../../../../../utils/toggleArrayItem';
import {
    addSymbolsModelType,
    deleteSymbolEditModelType,
} from '../../../../../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { useDispatch } from 'react-redux';
import { openSymbolEditorTab } from '../../../../../../../actions/methodologySetting.actions';
import theme from './DndSortTable.scss';
import { editObjectType } from '../../../../../../../actions/objectType.actions';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { getSymbolsDeleteMessages } from '../../util/metodologyElementsDeleteMessages.utils';
import { editModelTypeSymbolAttributeType } from '@/actions/symbol/symbolEditor.actions';
import { getObjectAvailableAttributeTypes } from '../utils/modelTypes.utils';
import { Alert } from 'antd';

type TSymbolsTabProps = {
    availableAttributeTypes: AttributeType[];
    modelType: ModelType;
    modelTypeSymbols: Symbol[];
    symbols: Symbol[];
    serverId: string;
    serverNode: TreeNode;
    preset: TPreset;
};

const SymbolsTab = (props: TSymbolsTabProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const { availableAttributeTypes, modelTypeSymbols, modelType, symbols, serverId, serverNode, preset } = props;
    const modelTypeId = modelType.id;
    const presetId = preset.id;
    const deleteSymbolsMessage = getSymbolsDeleteMessages(symbols, selectedRowKeys);

    const converterInitialized = (methodologiesGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(methodologiesGraph);
        }
    };

    const onAddSymbols = useCallback((symbolsForAdd: Symbol[]) => {
        dispatch(addSymbolsModelType({ serverId, presetId, modelTypeId, symbols: symbolsForAdd }));
    }, []);

    const onDeleteSymbols = useCallback((symbolsForDelete: Symbol[]) => {
        dispatch(deleteSymbolEditModelType({ symbols: symbolsForDelete, presetId, serverId, modelTypeId }));
        setSelectedRowKeys([]);
    }, []);

    const onEditObjectType = useCallback((objectTypeId: string) => {
        dispatch(editObjectType({ objectTypeId, preset, serverNode }));
    }, []);

    const onEditSymbol = useCallback((symbol: Symbol, objectTypeId: string) => {
        dispatch(openSymbolEditorTab({ symbol, objectTypeId, preset, serverNode }));
    }, []);

    const onOpenAttributeSettings = useCallback(
        (symbol: Symbol) => {
            const { definitionAttributes, instanceAttributes } = getObjectAvailableAttributeTypes(
                availableAttributeTypes,
                symbol.objectType,
                modelType,
            );

            dispatch(
                editModelTypeSymbolAttributeType({
                    definitionAttributes,
                    instanceAttributes,
                    serverNode,
                    symbol,
                    modelType,
                }),
            );
        },
        [modelType, availableAttributeTypes],
    );

    const renderRowActionButtons = (symbol: Symbol) => {
        const deleteContent = (
            <Alert
                message={
                    <>
                        <b>{intl.formatMessage(messages.deleteSymbols)}</b>
                        {`"${LocalesService.internationalStringToString(symbol.multilingualName)}"`}
                    </>
                }
                type="warning"
            />
        );

        return (
            <RowButtons
                buttons={[
                    EditButton.build(() => {}, undefined, undefined, {
                        items: [
                            {
                                key: 'EDIT_SYMBOL',
                                message: messages.EDIT_SYMBOL,
                                onClick: () => onEditSymbol(symbol, symbol.objectType),
                            },
                            {
                                key: 'EDIT_OBJECT',
                                message: messages.EDIT_OBJECT,
                                onClick: () => onEditObjectType(symbol.objectType),
                            },
                        ],
                    }),
                    SettingsButton.build(() => onOpenAttributeSettings(symbol)),
                    DeleteButton.build(
                        () => onDeleteSymbols([symbol]),
                        undefined,
                        undefined,
                        undefined,
                        intl.formatMessage(messages.deleteSymbolsDialogTitle),
                        deleteContent,
                    ),
                ]}
            />
        );
    };

    const onRow = (symbolId: string) => {
        const newState: string[] = toggleArrayItem<string>(symbolId, selectedRowKeys);
        setSelectedRowKeys(newState);
    };

    let filteredSymbols: Symbol[] = [];

    if (searchFilter) {
        filteredSymbols = modelTypeSymbols.filter((type) =>
            contains([type.id, type.name, type.objectType], searchFilter),
        );
    }

    return (
        <div className={theme.container}>
            {dialogVisible && (
                <SymbolsTabDialog
                    availableSymbols={symbols}
                    modelTypeSymbols={modelTypeSymbols}
                    graph={graph}
                    onAddSymbols={(symbolsForAdd) => {
                        setDialogVisible(false);
                        onAddSymbols(symbolsForAdd);
                    }}
                    onCancel={() => {
                        setDialogVisible(false);
                    }}
                />
            )}
            <SymbolToImageConverterGraph modelType={modelType} initialized={converterInitialized} />
            <TabHeader
                buttons={[
                    {
                        name: messages.newSymbol,
                        onAction: () => {
                            setDialogVisible(true);
                        },
                        dataTestId: 'model-type-editor_new-symbol-btn',
                    },
                    DeleteSelected.build(
                        () => {
                            onDeleteSymbols(
                                modelTypeSymbols.filter((symbol) => selectedRowKeys.some((key) => key === symbol.id)),
                            );
                        },
                        !selectedRowKeys.length,
                        undefined,
                        intl.formatMessage(messages.deleteSymbolsDialogTitle),
                        deleteSymbolsMessage,
                    ),
                ]}
                onSearchChange={setSearchFilter}
            />
            <DndSortTable
                rowSelection={{
                    selectedRowKeys,
                    onChange: (keys: string[]) => setSelectedRowKeys(keys),
                }}
                symbols={searchFilter ? filteredSymbols : modelTypeSymbols}
                onChangeSymbolsOrder={onAddSymbols}
                searchIsActive={!!searchFilter}
                onRow={onRow}
                intl={intl}
                graph={graph}
                renderRowActionButtons={renderRowActionButtons}
            />
        </div>
    );
};

const withMemo = React.memo(SymbolsTab);

export { withMemo as SymbolsTab };
