import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Alert, ConfigProvider, Empty, Form, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import messages from '../../messages/InstancePermissionTable.messages';
import { PermissionSwitch } from '../PermissionSwitch/PermissionSwitch.component';
import theme from './InstancePermissionsTable.scss';
import {
    PermissionModel,
    PermissionModelPermissionEnum,
    PrincipalDescriptor,
    PrincipalPermissions,
} from '../../../../serverapi/api';
import { isUndefined } from 'is-what';
import { getColumnsData } from './PermissionTablesUtils';
import { TableRowSelection } from 'antd/es/table/interface';
import { IPrincipalPermissionsColumnData } from './PermissionTables.types';

type TInstancePermissionsTableProps = {
    loading: boolean;
    filter: string;
    data: Array<PrincipalPermissions>;
    principals: Array<PrincipalDescriptor>;
    selectedIds: number[];
    // tslint:disable-next-line:no-any
    onChange: (principalId: number, column: string, isGranting: boolean | undefined) => any;
    onSelectionChanged: (selectedIds: number[]) => void;
    error: string | null;
} & JSX.IntrinsicAttributes;

type TInstancePermissionsTableAllProps = WrappedComponentProps & TInstancePermissionsTableProps;

class InstancePermissionsTable extends React.Component<TInstancePermissionsTableAllProps> {
    columns: Array<ColumnProps<IPrincipalPermissionsColumnData>>;

    constructor(props: TInstancePermissionsTableAllProps) {
        super(props);
        const { intl } = props;

        this.columns = [
            {
                width: 170,
                title: intl.formatMessage(messages.authority),
                dataIndex: 'principal',
                render: (value: PrincipalDescriptor) => <PrincipalLabel {...value} />,
            },
            {
                title: intl.formatMessage(messages.create),
                dataIndex: 'authCreate',
                render: (model: PermissionModel, record: IPrincipalPermissionsColumnData) => {
                    return (
                        <div className={theme.permissionCell} data-test="permission-table_column_CREATE">
                            {this.processAndRender(model, 'CREATE', record)}
                        </div>
                    );
                },
            },
            {
                title: intl.formatMessage(messages.read),
                dataIndex: 'authRead',

                render: (model: PermissionModel, record: IPrincipalPermissionsColumnData) => {
                    return (
                        <div className={theme.permissionCell} data-test="permission-table_column_READ">
                            {this.processAndRender(model, 'READ', record)}
                        </div>
                    );
                },
            },
            {
                title: intl.formatMessage(messages.write),
                dataIndex: 'authWrite',
                render: (model: PermissionModel, record: IPrincipalPermissionsColumnData) => {
                    return (
                        <div className={theme.permissionCell} data-test="permission-table_column_UPDATE">
                            {this.processAndRender(model, 'UPDATE', record)}
                        </div>
                    );
                },
            },
            {
                title: intl.formatMessage(messages.delete),
                dataIndex: 'authDelete',
                render: (model: PermissionModel, record: IPrincipalPermissionsColumnData) => {
                    return (
                        <div className={theme.permissionCell} data-test="permission-table_column_DELETE">
                            {this.processAndRender(model, 'DELETE', record)}
                        </div>
                    );
                },
            },
            {
                title: intl.formatMessage(messages.administration),
                dataIndex: 'authAdministration',
                render: (model: PermissionModel, record: IPrincipalPermissionsColumnData) => {
                    return (
                        <div className={theme.permissionCell} data-test="permission-table_column_CONTROL">
                            {this.processAndRender(model, 'CONTROL', record)}
                        </div>
                    );
                },
            },
        ];
    }

    handleRowSelectionChange(selectedRowKeys: string[]) {
        this.props.onSelectionChanged(selectedRowKeys.map((e) => Number(e)));
    }

    processAndRender(
        model: PermissionModel,
        actionType: PermissionModelPermissionEnum,
        record: IPrincipalPermissionsColumnData,
    ) {
        const currIsGranting: boolean | undefined = !isUndefined(model) ? model.isGranting : undefined;

        return this.renderColumns(currIsGranting, this.nextGranting(currIsGranting), record, actionType);
    }

    nextGranting(granting: boolean | undefined): boolean | undefined {
        if (granting === undefined) {
            return true;
        }
        if (granting === true) {
            return false;
        }

        return undefined;
    }

    renderColumns(
        currIsGranting: boolean | undefined,
        nextIsGranting: boolean | undefined,
        record: IPrincipalPermissionsColumnData,
        actionType: PermissionModelPermissionEnum,
    ) {
        const onClick = () =>
            this.props.onChange(record!.principalPermissions!.principalId!, actionType, nextIsGranting);

        return (
            <div className={theme.permissionCell} onClick={onClick}>
                <PermissionSwitch isGranting={currIsGranting} propagation />
            </div>
        );
    }

    render() {
        const { data, filter, selectedIds, principals, intl, error } = this.props;

        const selectedRowKeys = selectedIds.map((e) => String(e));
        const columnsData = getColumnsData(principals, data, filter);
        const rowSelection: TableRowSelection<IPrincipalPermissionsColumnData> = {
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: (keys) => this.handleRowSelectionChange(keys as string[]),
            columnWidth: 44,
        };

        return (
            <div
                style={columnsData.length ? { flexDirection: 'column' } : { flexDirection: 'row' }}
                className={theme.container}
            >
                {error && (
                    <Form.Item>
                        <Alert type="error" message={error} showIcon />
                    </Form.Item>
                )}
                <ConfigProvider renderEmpty={() => <Empty description={intl.formatMessage(messages.emptyTable)} />}>
                    <Table
                        rowKey={(record: IPrincipalPermissionsColumnData) =>
                            `${record.principalPermissions!.principalId}`
                        }
                        columns={this.columns}
                        dataSource={columnsData}
                        rowSelection={rowSelection}
                        className={theme.table}
                        pagination={false}
                        scroll={{
                            y: 'max-content',
                            x: 'max-content',
                        }}
                    />
                </ConfigProvider>
            </div>
        );
    }
}

const InstancePermissionsTableWithIntl = injectIntl(InstancePermissionsTable);

export { InstancePermissionsTableWithIntl as InstancePermissionsTable };
