import { Tooltip, Button } from 'antd';
import React, { FC } from 'react';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';
import icDelete from '../../../resources/icons/delete.svg';
import { useIntl } from 'react-intl';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { getStore } from '../../../store';

type TDeleteButton = {
    onDelete: () => void;
    deleteQuestion?: string;
    dialogContent?: React.ReactNode;
    disabled?: boolean;
};

export const showDeleteConfirmation = (props: TDeleteButton) => {
    const store = getStore();
    const {dispatch} = store;
    dispatch(openDialog(DialogType.DELETE_CONFIRMATION, props));
};

const DeleteButton: FC<TDeleteButton> = (props) => (
    <Tooltip mouseLeaveDelay={0} title={useIntl().formatMessage(messages.delete)}>
        <Button
            disabled={props.disabled}
            data-test="row-button_DELETE"
            type="link"
            onClick={() => showDeleteConfirmation(props)}
            icon={<Icon spriteSymbol={icDelete} />}
        />
    </Tooltip>
);

export default DeleteButton;
