import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import theme from '../NewMatrixEditor.scss';
import { InternationalString, MatrixDataBPM8764 } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { Locale } from '@/modules/Header/components/Header/header.types';
import messages from './NewMatrixMainHeader.messages';
import { useIntl } from 'react-intl';

type TNewMatrixMainHeaderProps = {
    currentLocale: Locale;
    data?: MatrixDataBPM8764;
    saveMatrixData: (data: MatrixDataBPM8764) => void;
    openRenameDialog: (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => void;
};

export const NewMatrixMainHeader: FC<TNewMatrixMainHeaderProps> = ({
    data,
    currentLocale,
    saveMatrixData,
    openRenameDialog,
}) => {
    const intl = useIntl();

    const localeColumnsName =
        LocalesService.internationalStringToString(data?.columnsName, currentLocale) ||
        intl.formatMessage(messages.withoutName);
    const localeRowumnsName =
        LocalesService.internationalStringToString(data?.rowsName, currentLocale) ||
        intl.formatMessage(messages.withoutName);

    const mainHeaderColRename = (newName: InternationalString) => {
        if (data) {
            const newMatrixData: MatrixDataBPM8764 = { ...data, columnsName: newName };
            saveMatrixData(newMatrixData);
        }
    };

    const mainHeaderRowRename = (newName: InternationalString) => {
        if (data) {
            const newMatrixData: MatrixDataBPM8764 = { ...data, rowsName: newName };
            saveMatrixData(newMatrixData);
        }
    };

    return (
        <Table.HeaderCell className={theme.activeCell}>
            <div className={theme.headerContainer}>
                <div className={theme.rotateContainer}>
                    <div
                        className={theme.colHeader}
                        onClick={() => openRenameDialog(mainHeaderColRename, data?.columnsName)}
                    >
                        <div className={theme.mainHeaderText}>{localeColumnsName}</div>
                    </div>
                    <div
                        className={theme.rowHeader}
                        onClick={() => openRenameDialog(mainHeaderRowRename, data?.rowsName)}
                    >
                        <div className={theme.mainHeaderText}>{localeRowumnsName}</div>
                    </div>
                </div>
            </div>
        </Table.HeaderCell>
    );
};
