import React, { useState } from 'react';
import { Select } from '../Select/Select.component';
import { SelectValue } from '../SelectValue/SelectValue';
import theme from './QuerySelect.scss';
import { TQuerySelectProps } from './QuerySelect.types';
import { AttributeValueQuery } from '../../../../serverapi/api';

export const QuerySelect = (props: TQuerySelectProps) => {
    const { data, initialValue = [], onChange, isMultiSelect } = props;
    const [queryArray, setQueryArray] = useState<AttributeValueQuery[]>(
        isMultiSelect || !initialValue.length ? initialValue : [initialValue[0]],
    );
    const sortedData: AttributeValueQuery[] = data
        .filter((value) => value.id)
        .sort((a, b) => {
            if (!a.value || !b.value) return 0;
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
                return -1;
            }
            if (a.value.toLowerCase() > b.value.toLowerCase()) {
                return 1;
            }

            return 0;
        });
    const children: JSX.Element[] = sortedData.map((element) => {
        return (
            <Select.Option
                key={element.id}
                label={element.value}
                value={element.id}
                checked={queryArray.some((query) => query.id === element.id)}
            />
        );
    });
    const unFoundQueryAttribute: AttributeValueQuery[] = initialValue.filter(
        (value) => !data.some((el) => el.id === value.id),
    );

    const onHandleChange = (ids: string[] | string | undefined) => {
        if (!ids) {
            setQueryArray([]);
            onChange([]);

            return;
        }
        if (!isMultiSelect) {
            const newValue: AttributeValueQuery | undefined = data.find((el) => el.id === ids);
            setQueryArray(newValue ? [newValue] : []);
            onChange(newValue ? [newValue] : []);

            return;
        }
        const newValue: AttributeValueQuery[] = data.filter((el) => (ids as string[]).includes(el.id));
        if (unFoundQueryAttribute.length) {
            const unFoundQueryToAdd: AttributeValueQuery[] = queryArray.filter((query) =>
                unFoundQueryAttribute.some((unFoundQuery) => unFoundQuery.id === query.id),
            );
            newValue.push(...unFoundQueryToAdd);
        }

        setQueryArray(newValue);
        onChange(newValue);
    };

    const onHandleDelete = (id: string) => {
        const newValue: AttributeValueQuery[] = queryArray.filter((query) => query.id !== id);
        setQueryArray(newValue);
        onChange(newValue);
    };

    const getValues = (queryArray: AttributeValueQuery[] | undefined) => {
        if (!queryArray) return '';

        return (
            <div className={theme.container}>
                {queryArray.map((query) => {
                    return (
                        <SelectValue
                            value={query.value}
                            deleteValue={() => onHandleDelete(query.id)}
                            isNotFound={unFoundQueryAttribute.some((unFoundQuery) => unFoundQuery.id === query.id)}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className={theme.selectContainer} data-test="querySelect-container">
            <Select
                isMultiSelect={isMultiSelect}
                showSearch
                value={getValues(queryArray)}
                onChange={onHandleChange}
                data-test="querySelect"
            >
                {children}
            </Select>
        </div>
    );
};
