import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { Symbol } from '../../../../../../../serverapi/api';
import { toggleArraySymbol } from '../../../../../../../utils/toggleArraySymbol';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import { Column, Table } from 'react-virtualized';
import messages from '../ModelType.messages';
import theme from './SymbolsTabDialog.scss';

const WIDTH_TABLE_CONTAINER = 432;
const HEIGHT_TABLE_CONTAINER = 430;
const HEADER_HEIGHT = 35;
const ROW_HEIGHT = 80;

type TSymbolsTabDialogIntlProps = {
    availableSymbols: Symbol[];
    modelTypeSymbols: Symbol[];
    graph?: MethodologiesGraph;
};

type TSymbolsTabDialogIntlActionProps = {
    onCancel: () => void;
    onAddSymbols: (symbols: Symbol[]) => void;
};

type TSymbolsTabDialogIntlFullProps = WrappedComponentProps &
    TSymbolsTabDialogIntlProps &
    TSymbolsTabDialogIntlActionProps;

type TWidthsTableParts = {
    checkbox: number;
    name: number;
    symbol: number;
};

type TSymbolsTabDialogState = {
    newSymbols: Symbol[];
    symbolFilter?: string;
    widthsTableParts: TWidthsTableParts;
};

class SymbolsTabDialog extends Component<TSymbolsTabDialogIntlFullProps, TSymbolsTabDialogState> {
    constructor(props: TSymbolsTabDialogIntlFullProps) {
        super(props);
        this.state = {
            newSymbols: [],
            symbolFilter: '',
            widthsTableParts: {
                checkbox: 0.1,
                name: 0.55,
                symbol: 0.35,
            },
        };
    }

    headerRenderer = ({ dataKey, label }) => {
        return (
            <React.Fragment key={dataKey}>
                <div className={theme.tableLabel}>{label}</div>
            </React.Fragment>
        );
    };

    filter = (symbol: Symbol, filter?: string) => {
        if (!filter) {
            return true;
        }
        const filterL = filter.toLowerCase();

        return symbol.name?.toLocaleLowerCase().includes(filterL) || symbol.id?.toLocaleLowerCase().includes(filterL);
    };

    onRow = (symbol: Symbol) => {
        const { newSymbols } = this.state;
        const newState: Symbol[] = toggleArraySymbol(symbol, newSymbols);

        this.setState({ newSymbols: newState });
    };

    renderDialogRowActionButtons(symbol: Symbol) {
        const { newSymbols } = this.state;

        return (
            <div data-test={`symbol-to-modelType-dialog_checkbox_${symbol.name}`}>
                <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                        const symbols = (newSymbols || []).filter((s) => s.id !== symbol.id);
                        if (e.target.checked) {
                            symbols.push(symbol);
                        }
                        this.setState({ newSymbols: symbols });
                    }}
                    checked={Boolean(this.state.newSymbols?.find((s) => s.id === symbol.id))}
                />
            </div>
        );
    }

    renderSymbolCell = (rowData: Symbol) => {
        return SymbolToImageConverterGraph.convertSymbol(rowData, this.props.intl, this.props.graph);
    };

    render() {
        const modelSymbolsIds = this.props.modelTypeSymbols.map((symbol: Symbol) => symbol.id);
        const symbols = this.props.availableSymbols
            .filter((symbol: Symbol) => !modelSymbolsIds.includes(symbol.id))
            .filter((symbol: Symbol) => this.filter(symbol, this.state.symbolFilter));
        const { intl } = this.props;
        const { widthsTableParts } = this.state;

        return (
            <Dialog
                onOk={() => {
                    const modelTypeSymbols = [...this.props.modelTypeSymbols, ...(this.state.newSymbols || [])];
                    this.props.onAddSymbols(modelTypeSymbols);
                }}
                onCancel={this.props.onCancel}
                open
                title={intl.formatMessage(messages.addSymbolsTitle)}
                okText={<div data-test="add-symbol-window_save-btn">{intl.formatMessage(messages.addSymbolConfirmButton)}</div>}
                cancelText={intl.formatMessage(messages.addSymbolDeclineButton)}
            >
                <div className={theme.creationTitle} data-test="add-symbol-window">
                    <Input
                        suffix={<SearchOutlined />}
                        data-test="add-symbol-window_filter"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.setState({ symbolFilter: e.target.value })
                        }
                    />
                    <Table
                        width={WIDTH_TABLE_CONTAINER}
                        height={HEIGHT_TABLE_CONTAINER}
                        headerHeight={HEADER_HEIGHT}
                        rowHeight={ROW_HEIGHT}
                        rowCount={symbols.length}
                        rowGetter={({ index }) => symbols[index]}
                        onRowClick={({ rowData }) => this.onRow(rowData)}
                        rowClassName={theme.tableRow}
                    >
                        <Column
                            headerRenderer={this.headerRenderer}
                            dataKey="checkbox"
                            width={widthsTableParts.checkbox * WIDTH_TABLE_CONTAINER}
                            cellRenderer={({ rowData }) => this.renderDialogRowActionButtons(rowData)}
                            className={theme.tableCheckbox}
                        />
                        <Column
                            headerRenderer={this.headerRenderer}
                            dataKey="name"
                            label={this.props.intl.formatMessage(messages.name)}
                            width={widthsTableParts.name * WIDTH_TABLE_CONTAINER}
                        />
                        <Column
                            headerRenderer={this.headerRenderer}
                            dataKey="description"
                            label={this.props.intl.formatMessage(messages.symbol)}
                            width={widthsTableParts.symbol * WIDTH_TABLE_CONTAINER}
                            cellRenderer={({ rowData }) => this.renderSymbolCell(rowData)}
                        />
                    </Table>
                </div>
            </Dialog>
        );
    }
}

const SymbolsTabDialogIntl = injectIntl(SymbolsTabDialog);

export { SymbolsTabDialogIntl as SymbolsTabDialog };
