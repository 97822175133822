import type { TFileUploadFailAction, TFileUploadSuccessAction } from '@/actions/uploader.actions.types';
import type { NodeId } from '@/serverapi/api';
import type { Editor } from '@tiptap/react';
import { put, select, takeEvery } from 'redux-saga/effects';
import {
    wikiCreateFail,
    wikiCreateSuccess,
    wikiRemoveSuccess,
    wikiRequestSuccess,
    wikiSetIsClipboardAvailable,
} from '../actions/entities/wiki.actions';
import {
    TWikiDefaultAction,
    TWikiOpenByIdAction,
    TWikiChangeLocallyAction,
    TWikiUploadImageAction,
} from '../actions/entities/wiki.actions.types';
import {
    WIKI_CHANGE_LOCALLY_REQUEST,
    WIKI_CREATE,
    WIKI_OPEN_BY_ID,
    WIKI_UPLOAD_IMAGE,
} from '../actionsTypes/entities/wiki.actionTypes';
import { v4 as uuid } from 'uuid';
import { TWikiEditorSelectionProps, IWorkspaceTabItemWikiParams, TWorkspaceTab } from '../models/tab.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemAdd } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { workspaceAddTab, workspaceRemoveTab } from '../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../actions/tabs.actions.types';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { IWikiNode } from '../models/bpm/bpm-model-impl.types';
import { WikiSelectors } from '../selectors/entities/wiki.selectors';
import { TreeNode } from '../models/tree.types';
import { WIKI_DIAGRAM_TYPE_ID } from '../models/tree';
import { setServerIdToNodeOriginal } from '../utils/nodeId.utils';
import { recentAddModel } from '../actions/recent.actions';
import messages from '../modules/Wiki/messages/WikiEditor.messages';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { getIsClipboardAvailable } from '../utils/clipboardUtils';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { WikiDaoService } from '@/services/dao/WikiDAOService';
import { clearStateComments } from '@/actions/comments.actions';
import { WIKI_SOURCE_PROP_NAME } from '@/modules/Wiki/WikiEditor.utils';
import { FILE_UPLOAD_FAIL, FILE_UPLOAD_SUCCESS } from '@/actionsTypes/uploader.actionTypes';
import { fileUpload } from '@/actions/uploader.actions';
import { instancesWikiEditorMap } from '@/mxgraph/wiki-editor-instance-map';

function* handleTreeItemsWikiAdd({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository) &&
        action === TreeItemContextMenuAction.ADD_WIKI
    ) {
        yield put(
            openDialog(DialogType.WIKI_CREATE_DIALOG, {
                parentNodeId: nodeId,
            }),
        );
    }
}

export function* handleWikiCreate(action: TWikiDefaultAction) {
    let {
        wiki,
        wiki: { parentNodeId },
    } = action.payload;

    if (parentNodeId) {
        const nodeId = {
            ...parentNodeId,
            id: uuid(),
        };
        wiki = {
            ...wiki,
            nodeId,
            [WIKI_SOURCE_PROP_NAME]: '',
            type: TreeItemType.Wiki,
            serverId: parentNodeId.serverId,
        } as IWikiNode;

        const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
            title: wiki.name,
            type: 'WikiEditor',
            nodeId,
            content: wiki,
            params: <IWorkspaceTabItemWikiParams>{ selectionProps: <TWikiEditorSelectionProps>{} },
        };

        try {
            wiki = yield WikiDaoService.saveWiki(nodeId.serverId, wiki);
        } catch (e) {
            wikiCreateFail(wiki); // оповещение при создании wiki через диалог декомпозиции
            throw e;
        }

        if (wiki) {
            setServerIdToNodeOriginal(wiki, parentNodeId.serverId);
            yield put(wikiRequestSuccess(wiki));
            yield put(
                treeItemAdd({
                    ...wiki,
                    type: wiki.type as TreeItemType,
                    hasChildren: false,
                } as TreeNode),
            );
            yield put(workspaceAddTab(workspaceTab));
            yield addRecent(wiki);
            yield put(wikiCreateSuccess(wiki)); // оповещение при создании wiki через диалог декомпозиции
        }
        yield put(closeDialog(DialogType.WIKI_CREATE_DIALOG));
    }
}

function* handleTabWikiClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;
    if (workspaceTab.content && workspaceTab.content.type === TreeItemType.Wiki) {
        yield put(workspaceRemoveTab(workspaceTab));
        yield put(clearStateComments(workspaceTab.nodeId));
        yield put(wikiRemoveSuccess(workspaceTab.nodeId));
    }
}

function* handleWikiOpenByTreeNode(action: TWikiOpenByIdAction) {
    const {
        nodeId: { serverId, repositoryId, id },
    } = action.payload;
    try {
        const wiki: IWikiNode = yield WikiDaoService.getWikiById(serverId, repositoryId, id);

        if (wiki) {
            setServerIdToNodeOriginal(wiki, serverId);

            const isClipboardAvailable: boolean = yield getIsClipboardAvailable();

            yield put(wikiSetIsClipboardAvailable(isClipboardAvailable));
            yield put(wikiRequestSuccess(wiki));

            const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
                title: wiki.name,
                type: WIKI_DIAGRAM_TYPE_ID,
                nodeId: wiki.nodeId,
                content: wiki,
                params: <IWorkspaceTabItemWikiParams>{ selectionProps: <TWikiEditorSelectionProps>{} },
            };
            yield put(workspaceAddTab(workspaceTab));
            yield addRecent(wiki);
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
        } else {
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        }
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* addRecent(wiki: IWikiNode) {
    yield put(
        recentAddModel({
            nodeId: wiki.nodeId,
            type: TreeItemType.Wiki,
            parentId: wiki.parentNodeId || null,
            createdAt: new Date().toISOString(),
            title: wiki.name,
            modelTypeId: WIKI_DIAGRAM_TYPE_ID,
            modelTypeName: LocalesService.useIntl(yield select(getCurrentLocale)).formatMessage(messages.wikiModel),
            messageDescriptor: messages.wikiModel,
        }),
    );
}

function* handleWikiChangeLocallyRequest(action: TWikiChangeLocallyAction) {
    const { source, [WIKI_SOURCE_PROP_NAME]: wikiSource, nodeId } = action.payload;
    const wiki: IWikiNode = yield select(WikiSelectors.byId(nodeId));

    if (wiki && (source !== wiki.source || wikiSource !== wiki[WIKI_SOURCE_PROP_NAME])) {
        wiki.source = source;
        wiki.source2 = wikiSource;

        const savedWiki: IWikiNode = yield WikiDaoService.saveWiki(nodeId.serverId, wiki);
        setServerIdToNodeOriginal(savedWiki, nodeId.serverId);
        yield put(wikiRequestSuccess(savedWiki));
    }
}

function* handleWikiUploadImage(action: TWikiUploadImageAction) {
    const { file, fileId } = action.payload;

    yield put(fileUpload(file, fileId));
}

function* getUploadingImageEditor(imageId: string) {
    const openedEditors = instancesWikiEditorMap.keys();

    if (openedEditors.length === 0) {
        return;
    }

    let uploadedImageEditorId: NodeId | null = null;

    for (let i = 0; i < openedEditors.length; i++) {
        const openedEditorId = openedEditors[i];
        const editorUpload: Record<string, string> = yield select(WikiSelectors.imageUpload(openedEditorId));

        if (editorUpload[imageId]) {
            uploadedImageEditorId = openedEditorId;

            break;
        }
    }

    if (!uploadedImageEditorId) {
        return;
    }

    return instancesWikiEditorMap.get(uploadedImageEditorId);
}

function* handleFileUploadSuccess(action: TFileUploadSuccessAction) {
    const { id } = action.payload;
    const uploadingImageEditor: Editor | undefined = yield getUploadingImageEditor(id);

    if (!uploadingImageEditor) {
        return;
    }

    uploadingImageEditor.commands.refreshImage(id);
}

function* handleFileUploadFail(action: TFileUploadFailAction) {
    const { id } = action.payload;
    const uploadingImageEditor: Editor | undefined = yield getUploadingImageEditor(id);

    if (!uploadingImageEditor) {
        return;
    }

    uploadingImageEditor.commands.removeImage(id);
}

export function* wikiSaga() {
    yield takeEvery(WIKI_CREATE, handleWikiCreate);
    yield takeEvery(WIKI_OPEN_BY_ID, handleWikiOpenByTreeNode);
    yield takeEvery(WIKI_CHANGE_LOCALLY_REQUEST, handleWikiChangeLocallyRequest);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsWikiAdd);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabWikiClose);
    yield takeEvery(WIKI_UPLOAD_IMAGE, handleWikiUploadImage);
    yield takeEvery(FILE_UPLOAD_SUCCESS, handleFileUploadSuccess);
    yield takeEvery(FILE_UPLOAD_FAIL, handleFileUploadFail);
}
