export enum EdgeSymbolType {
    custom = 'CUSTOM',
    icon = 'ICON',
    userIcon = 'USER_ICON',
}

export enum IconType {
    plus = 'PLUS',
    minus = 'MINUS',
    check = 'CHECK',
    cross = 'CROSS',
}

export enum HeaderType {
    row = 'row',
    column = 'column',
}

export enum ContextActions {
    levelUp = 'levelUp',
    levelDown = 'levelDown',
    symbolsSettings = 'symbolsSettings',
}
