import React, { useState, ChangeEvent, KeyboardEvent, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import { getSearchResult } from '../../../actions/search.actions';
import { showNotificationByType } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import messages from './SearchField.messages';
import style from './SearchField.scss';
import { useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Keys } from '../../../utils/keys';
import { ISearchRuleWithId } from '../AttributeFilter/AttributeFilter.types';
import { SearchSelectors } from '@/selectors/dbSearch.selector';

type TSearchFieldProps = {
    searchRules: ISearchRuleWithId[];
};

export const SearchField: FC<TSearchFieldProps> = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isLoading: boolean = useSelector(SearchSelectors.getLoadingStatus);

    const [searchText, setSearchText] = useState('');

    const handleSearch = () => {
        const hasAttributeFilterError = props.searchRules.some(
            (rule) => rule.queryRule !== 'HAS_VALUE' && (!rule.values.length || rule.values[0] === ''),
        );
        if (hasAttributeFilterError) {
            dispatch(showNotificationByType(NotificationType.ATTRIBUTE_FILTER_VALIDATE_ERROR));
        } else {
            dispatch(getSearchResult(searchText, props.searchRules));
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.trim();
        setSearchText(searchValue);
    };

    const handleKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === Keys.ENTER) {
            handleSearch();
        }
    };

    return (
        <div className={style.searchFieldContainer}>
            <Input
                data-test="search_search-field-input"
                autoFocus
                suffix={<SearchOutlined />}
                onChange={handleChange}
                onKeyPress={handleKeypress}
                style={{ width: 400 }}
                placeholder={intl.formatMessage(messages.placeholder)}
            />

            <Button
                data-test="search_search-start-button"
                key="ok"
                type="primary"
                onClick={handleSearch}
                disabled={isLoading}
            >
                {intl.formatMessage(messages.button)}
            </Button>
        </div>
    );
};
