import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'SymbolTypeSelectDialog.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'SymbolTypeSelectDialog.cancel',
        defaultMessage: 'Отмена',
    },
    selectSymbolType: {
        id: 'SymbolTypeSelectDialog.selectSymbolType',
        defaultMessage: 'Выберите вид символа',
    },
    placeholder: {
        id: 'SymbolTypeSelectDialog.placeholder',
        defaultMessage: 'Вид символа',
    }
});
