import { Button, Tooltip } from 'antd';
import React from 'react';
import messages from './EditorFooterButtons.messages';
import theme from './EditorFooterButtons.scss';
import { TBtnProperties } from '../Header/TabHeader.types';
import { showDeleteConfirmation } from '../../../../Button/DeleteButton';

type TEditorFooterButtonsProps = {
    buttons: Array<TBtnProperties>;
    deleteQuestion?: string;
};

export const EditorFooterButtons = (props: TEditorFooterButtonsProps) => {
    const { buttons, deleteQuestion } = props;

    return (
        <div className={theme.generalButtons}>
            {buttons.map((button) => {
                return (
                    <Tooltip title={button.tooltip} key={button.name}>
                        <Button
                            size={button.size || 'large'}
                            type={button.type}
                            disabled={button.disabled}
                            className={theme.button}
                            onClick={
                                button.name.id === messages.remove.id
                                    ? () =>
                                        showDeleteConfirmation({
                                            onDelete: () => button.onAction(null),
                                            deleteQuestion,
                                        })
                                    : button.onAction
                            }
                            data-test={button.dataTestId}
                        >
                            <span>{button.name}</span>
                        </Button>
                    </Tooltip>
                );
            })}
        </div>
    );
};
