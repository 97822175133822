import type { ObjectInstance } from '@/serverapi/api';
import type { MxCell } from 'MxGraph';
import { ComplexSymbolSerializer } from './ComplexSymbolSerializer.class';

export default class ObjectInstanceSerializer extends ComplexSymbolSerializer {
    rootCell: MxCell;

    afterCreate(rootCell: MxCell) {
        this.rootCell = rootCell;
    }

    serialize(): ObjectInstance {
        const root = this.rootCell;
        const rootCellValue = root.getValue();
        const { x, y, width, height } = root.getGeometry();
        const styles = this.replaceSystemStyles();

        return {
            ...rootCellValue,
            ...styles,
            parent: root.getParent().getId(),
            x,
            y,
            width,
            height,
        };
    }
}
