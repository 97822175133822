import { EditorMode } from '../../models/editorMode';
import { CustomMxEvent } from '../../sagas/editor.saga.constants';
import { BPMMxGraph } from '../bpmgraph';
import { MxCell, MxEdgeSegmentHandler, MxEvent, MxEventObject, MxMouseEvent, MxPoint } from '../mxgraph';
import { BPMMxConstraintHandler } from './BPMMxConstraintHandler.class';
import { v4 as uuid } from 'uuid';

export class BPMMxEdgeSegmentHandler extends MxEdgeSegmentHandler {
    init() {
        super.init();
        this.constraintHandler = new BPMMxConstraintHandler(this.graph);
    }

    getCurrentSourceTarget(focusedTarget) {
        const { isSource, state, isTarget } = this;
        const { cell: edge } = state;
        const { source, target } = edge;
        const s = isSource ? focusedTarget : source;
        const t = isTarget ? focusedTarget : target;

        return { edge, source: s, target: t, isSource, isTarget };
    }

    validateConnectionTo(focusedCell) {
        const { source, target } = this.getCurrentSourceTarget(focusedCell);

        return this.validateConnection(source, target);
    }

    validateConnection(source: MxCell, target: MxCell) {
        const edge = this.state.cell;
        const focusedCell = this.isSource ? source : target;

        const { isSource, isTarget } = this;
        if (isSource && edge.source === focusedCell) {
            return null;
        }
        if (isTarget && edge.target === focusedCell) {
            return null;
        }

        return this.graph.validateEdgeReconnect(edge, source, target);
    }

    getPointForEvent(me: MxMouseEvent) {
        if (me.sourceState) {
            const initialEvent: Event = me.getEvent();
            const fakeEvent: MxMouseEvent = <MxMouseEvent>new MxMouseEvent(initialEvent, me.sourceState);
            fakeEvent.graphX = me.getGraphX();
            fakeEvent.graphY = me.getGraphY();
            fakeEvent.evt = new fakeEvent.evt.constructor(fakeEvent.evt.type, {
                ...fakeEvent.evt,
                altKey: true,
            });

            const view = this.graph.getView();
            const { scale } = view;
            const point = new MxPoint(
                this.roundLength(me.getGraphX() / scale) * scale,
                this.roundLength(me.getGraphY() / scale) * scale,
            );

            const tt = this.getSnapToTerminalTolerance();

            if (tt > 0) {
                const snapToPoint = (pt: MxPoint) => {
                    if (pt != null) {
                        const { x } = pt;

                        if (Math.abs(point.x - x) < tt) {
                            point.x = x;
                        }

                        const { y } = pt;

                        if (Math.abs(point.y - y) < tt) {
                            point.y = y;
                        }
                    }
                };

                if (me.sourceState && me.sourceState.absolutePoints != null) {
                    for (let i = 0; i < me.sourceState.absolutePoints.length; i++) {
                        snapToPoint.call(this, me.sourceState.absolutePoints[i]);
                    }
                }
            }

            return point;
        }

        return super.getPointForEvent(me);
    }

    createBends() {
        return (this.graph as BPMMxGraph).mode === EditorMode.Read ? [] : super.createBends();
    }

    createCustomHandles() {
        const handles = super.createCustomHandles();
        const graph = this.graph as BPMMxGraph;

        return graph.mode === EditorMode.Read ? [] : handles;
    }

    isActive() {
        return this.active;
    }

    changePoints(edge: MxCell, points: MxPoint[], clone: boolean) {
        const model = this.graph.getModel();
        model.beginUpdate();
        try {
            if (clone) {
                const parent = model.getParent(edge);
                const source = model.getTerminal(edge, true);
                const target = model.getTerminal(edge, false);
                edge = this.graph.cloneCell(edge);
                model.add(parent, edge, model.getChildCount(parent));
                model.setTerminal(edge, source, true);
                model.setTerminal(edge, target, false);
            }

            let geo = model.getGeometry(edge);

            if (geo != null) {
                geo = geo.clone();
                geo.points = points;

                model.setGeometry(edge, geo);
            }
            this.graph.getModel().fireEvent(new MxEventObject(CustomMxEvent.UPDATE_CELLS_OVERLAYS, 'cells', [edge]));
        } finally {
            model.endUpdate();
        }

        return edge;
    }

    mouseUp(sender, me: MxMouseEvent) {
        // Workaround for wrong event source in Webkit
        if (this.index != null && this.marker != null) {
            if (this.shape != null && this.shape.node != null) {
                this.shape.node.style.display = '';
            }

            let edge = this.state.cell;
            const { index } = this;
            this.index = null;

            // Ignores event if mouse has not been moved
            if (me.getX() !== this.startX || me.getY() !== this.startY) {
                const clone: boolean =
                    !this.graph.isIgnoreTerminalEvent(me.getEvent()) &&
                    this.graph.isCloneEvent(me.getEvent()) &&
                    this.cloneEnabled &&
                    this.graph.isCellsCloneable();

                // Displays the reason for not carriying out the change
                // if there is an error message with non-zero length
                if (this.error != null) {
                    if (this.error.length > 0) {
                        this.graph.validationAlert(this.error);
                    }
                } else if (index <= MxEvent.CUSTOM_HANDLE && index > MxEvent.VIRTUAL_HANDLE) {
                    if (this.customHandles != null) {
                        const model = this.graph.getModel();

                        model.beginUpdate();
                        try {
                            this.customHandles[MxEvent.CUSTOM_HANDLE - index].execute(me);

                            if (this.shape != null && this.shape.node != null) {
                                this.shape.apply(this.state);
                                this.shape.redraw();
                            }
                        } finally {
                            model.endUpdate();
                        }
                    }
                } else if (this.isLabel) {
                    this.moveLabel(this.state, this.label.x, this.label.y);
                } else if (this.isSource || this.isTarget) {
                    let terminal = null;

                    if (
                        this.constraintHandler.currentConstraint != null &&
                        this.constraintHandler.currentFocus != null
                    ) {
                        terminal = this.constraintHandler.currentFocus.cell;
                    }

                    if (
                        terminal == null &&
                        this.marker.hasValidState() &&
                        this.marker.highlight != null &&
                        this.marker.highlight.shape != null &&
                        this.marker.highlight.shape.stroke !== 'transparent' &&
                        this.marker.highlight.shape.stroke !== 'white'
                    ) {
                        terminal = this.marker.validState.cell;
                    }

                    if (terminal != null) {
                        const model = this.graph.getModel();
                        const parent = model.getParent(edge);

                        model.beginUpdate();
                        try {
                            // Clones and adds the cell
                            if (clone) {
                                let geo = model.getGeometry(edge);
                                const cloneCell: MxCell = this.graph.cloneCell(edge);
                                const cloneCellId = uuid();
                                cloneCell.setId(cloneCellId);
                                const cloneCellValue = cloneCell.getValue();
                                if (cloneCellValue) {
                                    cloneCell.setValue({
                                        ...cloneCellValue,
                                        id: cloneCellId,
                                        attributes:
                                            cloneCellValue.attributes?.map((attr) => ({ ...attr, id: uuid() })) || [],
                                    });
                                }
                                model.add(parent, cloneCell, model.getChildCount(parent));

                                if (geo != null) {
                                    geo = geo.clone();
                                    model.setGeometry(cloneCell, geo);
                                }

                                const other = model.getTerminal(edge, !this.isSource);
                                this.graph.connectCell(cloneCell, other, !this.isSource);

                                edge = cloneCell;
                            }

                            edge = this.connect(edge, terminal, this.isSource, clone, me);
                            this.graph
                                .getModel()
                                .fireEvent(new MxEventObject(CustomMxEvent.UPDATE_CELLS_OVERLAYS, 'cells', [edge]));
                        } finally {
                            model.endUpdate();
                        }
                    } else if (this.graph.isAllowDanglingEdges()) {
                        const pt = this.abspoints[this.isSource ? 0 : this.abspoints.length - 1];
                        pt.x = this.roundLength(pt.x / this.graph.view.scale - this.graph.view.translate.x);
                        pt.y = this.roundLength(pt.y / this.graph.view.scale - this.graph.view.translate.y);

                        const pstate = this.graph.getView().getState(this.graph.getModel().getParent(edge));

                        if (pstate != null) {
                            pt.x -= pstate.origin.x;
                            pt.y -= pstate.origin.y;
                        }

                        pt.x -= this.graph.panDx / this.graph.view.scale;
                        pt.y -= this.graph.panDy / this.graph.view.scale;

                        // Destroys and recreates this handler
                        edge = this.changeTerminalPoint(edge, pt, this.isSource, clone);
                    }
                } else if (this.active) {
                    edge = this.changePoints(edge, this.points, clone);
                } else {
                    this.graph.getView().invalidate(this.state.cell);
                    this.graph.getView().validate(this.state.cell);
                }
            } else if (this.graph.isToggleEvent(me.getEvent())) {
                this.graph.selectCellForEvent(this.state.cell, me.getEvent());
            }

            // Resets the preview color the state of the handler if this
            // handler has not been recreated
            if (this.marker != null) {
                this.reset();

                // Updates the selection if the edge has been cloned
                if (edge !== this.state.cell) {
                    this.graph.setSelectionCell(edge);
                }
            }

            me.consume();
        }
    }
}
