export const getStyleValueByStyleKey = (key: string, style: string): string | undefined => {
    const styleValue = style?.split(';').reduce((acc, prop) => {
        const [property, propertyValue] = prop.split('=');
        if (property === key) {
            return propertyValue;
        }

        return acc;
    }, '');

    return styleValue || undefined;
};

export const inlineCssToStyleMap = (style: string): Map<string, string> | undefined => {
    const map = new Map();

    if (style) {
        style.split(';').forEach((el) => {
            if (!el.length) return;
            const [key, value] = el.split('=');
            map.set(key, value);
        });
    }

    let isLeastOneKeyOrValueUndefinedOrEmpty = false;

    Array.from(map).forEach((arr) => {
        const [key, value] = arr;
        if (!key || !value)
            isLeastOneKeyOrValueUndefinedOrEmpty = true;
    });

    if (map.size && !isLeastOneKeyOrValueUndefinedOrEmpty) return map;

    return undefined;
};

export const styleMapToInlineCss = (
    styleMap: Map<string, string>,
    propertySeparator: string = '=',
    declarationSeparator: string = ';',
): string | undefined => {
    let isLeastOneKeyOrValueUndefinedOrEmpty = false;
    let isKeyOrValueContainIncorrectCharaters = false;

    Array.from(styleMap).forEach((arr) => {
        const [key, value] = arr;
        if (!key || !value) isLeastOneKeyOrValueUndefinedOrEmpty = true;
        if ((key + value).match(/[^\w^.#]/g))
            isKeyOrValueContainIncorrectCharaters = true;
    });

    if (isLeastOneKeyOrValueUndefinedOrEmpty || isKeyOrValueContainIncorrectCharaters) {
        return undefined;
    }

    const inlineStyle = Array.from(
        styleMap,
        ([property, propertyValue]) =>
            property + propertySeparator + propertyValue + declarationSeparator,
    ).join('');

    if (inlineStyle.match(/[^\w^=;.#]/g)) return undefined;

    return inlineStyle;
};

export const getOverwrittenStyleMap = (
    styleMap: Map<string, string>,
    overwritingStyleMap: Map<string, string>,
): Map<string, string> => {
    const newMap = new Map(styleMap);
    let isKeyOrValueContainIncorrectCharaters = false;

    Array.from(overwritingStyleMap).forEach(arr => {
        const [key, value] = arr;
        if (!key || !value) return;
        if ((key + value).match(/[^\w^.#]/g))
            isKeyOrValueContainIncorrectCharaters = true;
        newMap.set(key, value);
    });
    if (isKeyOrValueContainIncorrectCharaters) return styleMap;

    return newMap;
};

export const getNewStyleMapWithoutDeletableProperties = (
    styleMap: Map<string, string>,
    removableProperties: string[],
): Map<string, string> => {
    const newMap = new Map(styleMap);

    removableProperties.forEach(property => {
        newMap.delete(property);
    });

    return newMap;
};

export const getShapeType = (style: string) => {
    let shapeType: string = 'shape';
    if (style.includes("strokeColor=none") || !style.includes("shape=")) {
        shapeType = 'text';
    }

    return shapeType;
};

export const getShapeId = (style: string) => {
    const styleProps: string[] = style.split(";");
    const symbolId = styleProps.find(prop => prop.startsWith("shape=")) || '';
   
    return symbolId;
};
