import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dropdown } from 'antd';
import theme from './TabHeader.scss';
import { getMenuItem } from '../../../../../../utils/antdMenuItem.utils';
import { Icon } from '../../../../../UIKit';
import { getAntdPopupContainer } from '../../../../../../utils/getAntdPopupContainer';
import { TBtnProperties } from './TabHeader.types';

type THeaderCreateButtonButtonProps = {
    title: string;
    itemButtons: Array<TBtnProperties>;
};

export const ButtonWithDropdown = (props: THeaderCreateButtonButtonProps) => {
    const { title, itemButtons } = props;
    const intl = useIntl();
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = useCallback(() => setOpen((prevOpen) => !prevOpen), []);

    const createOnClickFn = (onAction: Function) => () => {
        setOpen(false);
        onAction();
    };

    const items = itemButtons.map((button: TBtnProperties) =>
        getMenuItem(
            <div className={theme.menuItem} data-test={button.dataTestId} onClick={button.disabled ? undefined : createOnClickFn(button.onAction)}>
                <Icon className={theme.menuItemIcon} spriteSymbol={button.icon} />
                {intl.formatMessage(button.name)}
            </div>,
            button.key,
            button.disabled
        ),
    );

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ items }}
            trigger={['click']}
            getPopupContainer={getAntdPopupContainer}
            destroyPopupOnHide
            overlayClassName={theme.dropdown}
            placement="bottomRight"
        >
            <Button className={theme.button}>{title}</Button>
        </Dropdown>
    );
};
