// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlignButton__buttonWrapper__QkaIX,.AlignButton__buttonWrapperWithPadding__nok3b{position:relative}.AlignButton__buttonWrapper__QkaIX .ant-btn-selected,.AlignButton__buttonWrapperWithPadding__nok3b .ant-btn-selected{text-decoration:none;border-radius:0;background:rgba(39,107,238,.1)}.AlignButton__buttonWrapper__QkaIX .ant-btn:focus,.AlignButton__buttonWrapperWithPadding__nok3b .ant-btn:focus{color:rgba(0,0,0,.65);border-color:#d9d9d9}.AlignButton__buttonWrapperWithPadding__nok3b{padding-right:2px}", "",{"version":3,"sources":["webpack://./src/modules/FloatingAttributes/components/common/Buttons/AlignButton.scss"],"names":[],"mappings":"AAAA,iFAEI,iBAAA,CAGI,qHACI,oBAAA,CACA,eAAA,CACA,8BAAA,CAEJ,+GACI,qBAAA,CACA,oBAAA,CAKZ,8CACI,iBAAA","sourcesContent":[".buttonWrapper,\r\n.buttonWrapperWithPadding {\r\n    position: relative;\r\n\r\n    :global {\r\n        .ant-btn-selected {\r\n            text-decoration: none;\r\n            border-radius: 0;\r\n            background: rgba(39, 107, 238, 0.1);\r\n        }\r\n        .ant-btn:focus {\r\n            color: rgba(0, 0, 0, 0.65);\r\n            border-color: #d9d9d9;\r\n        }\r\n    }\r\n}\r\n\r\n.buttonWrapperWithPadding {\r\n    padding-right: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "AlignButton__buttonWrapper__QkaIX",
	"buttonWrapperWithPadding": "AlignButton__buttonWrapperWithPadding__nok3b"
};
export default ___CSS_LOADER_EXPORT___;
