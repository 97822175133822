export enum CommentStatus {
    open = 'OPEN',
    close = 'CLOSE',
}

export enum CommentFilterStatus {
    all = 'all',
    resolved = 'resolved',
    notResolved = 'notResolved',
}

export enum CommentMenuActions {
    commentEdit = 'COMMENT_EDIT',
    commentDelete = 'COMMENT_DELETE',
    commentMarkerDelete = 'COMMENT_MARKER_DELETE',
    commentMarkerAdd = 'COMMENT_MARKER_ADD',
    commentPin = 'COMMENT_PIN',
    commentUnpin = 'COMMENT_UNPIN',
    critical = 'CRITICAL',
}
