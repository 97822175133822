import React, { FC } from 'react';
import theme from './EdgeStyleSettings.scss';
import messages from './EdgeStyleSettings.messages';
import { Table } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { MatrixStyle } from '@/serverapi/api';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import cn from 'classnames';
import { renderCellSymbol } from '@/modules/Matrix/utils';

type TSymbolsTableProps = {
    currentStyles: MatrixStyle[];
    selectedSymbolId: string;
    onRowSelect: (symbolId: string) => void;
};

export const SymbolsTable: FC<TSymbolsTableProps> = ({ currentStyles, selectedSymbolId, onRowSelect }) => {
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const renderStyleRow = (style: MatrixStyle) => {
        const { id, description } = style;
        const localeDescription = LocalesService.internationalStringToString(description, currentLocale);

        const { symbol, color } = renderCellSymbol(style, theme.antdIconsContainer);

        return (
            <Table.Row
                key={id}
                className={cn(theme.symbolRow, { [theme.selected]: selectedSymbolId === id })}
                onClick={() => {
                    onRowSelect(id);
                }}
            >
                <Table.Cell className={theme.symbolCell} style={{ color: color }}>
                    {symbol}
                </Table.Cell>
                <Table.Cell className={theme.descriptionCell}>{localeDescription}</Table.Cell>
            </Table.Row>
        );
    };

    return (
        // @ts-ignore
        <Table definition className={theme.table}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="left">{intl.formatMessage(messages.iconSymbol)}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">{intl.formatMessage(messages.iconDescription)}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{currentStyles.map((style) => renderStyleRow(style))}</Table.Body>
        </Table>
    );
};
