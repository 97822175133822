import React, { FC, useCallback, useState } from 'react';
import { TPreset } from '../../../../../models/preset.types';
import { TreeNode } from '../../../../../models/tree.types';
import { MethodologiesGraph } from '../../../../../mxgraph/MethodologiesGraph';
import {
    EdgeType,
    EdgeTypeGroup,
    PresetElementTransferType,
    InternationalString,
    NodeId,
} from '../../../../../serverapi/api';
import messages from '../../messages/Presets.messages';
import edgeTypeDirectionMessages from '../../../../../models/edge-type-direction.messages';
import { GroupSelectionDialog } from './Dialog/GroupSelectionDialog/GroupSelectionDialog.component';
import { DeleteSelected, ExportButton, ImportButton, MoveSelected, TabHeader } from './Header/TabHeader.component';
import { SymbolToImageConverterGraph } from './SymbolToImageConverterGraph.component';
import { TTableRowRecord } from './util/GroupedTypesTable.types';
import { GroupedTypesTable } from './util/GroupedTypesTable.component';
import theme from './Presets.scss';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { EdgeTypeSelectors } from '../../../../../selectors/edgeType.selectors';
import { createEdgeType, deleteEdgeType, editEdgeType, submitEdgeType } from '../../../../../actions/edgeType.actions';
import {
    createEdgeTypeGroup,
    deleteEdgeTypeGroupRequest,
    editEdgeTypeGroup,
} from '../../../../../actions/edgeTypeGroup.actions';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { exportPreset } from '../../../../../actions/methodologySetting.actions';
import { EdgeTypeGroupSelectors } from '../../../../../selectors/edgeTypeGroup.selectors';
import { LocalesService } from '../../../../../services/LocalesService';
import { Alert } from 'antd';
import { getMetodologyElementsDeleteMessages } from './util/metodologyElementsDeleteMessages.utils';
import { ButtonWithDropdown } from './Header/ButtonWithDropdown.component';
import icFolder from '../../../../../resources/icons/group.svg';
import icImport from '../../../../../resources/icons/Import.svg';
import { PRESET_IMPORT_EXTENSIONS } from '../../../../FileUpload/types/FileUploadDialog.constants';

type TEdgeTypesTabProps = {
    disabled: boolean;
    preset: TPreset;
    serverNode: TreeNode;
    tabNodeId: NodeId;
};

export const EdgeTypesTab: FC<TEdgeTypesTabProps> = (props) => {
    const { preset, serverNode, disabled, tabNodeId } = props;

    const importProperties = {
        preset,
        filters: PRESET_IMPORT_EXTENSIONS,
    };

    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedEdgeTypes, setSelectedEdgeTypes] = useState<EdgeType[]>([]);
    const [selectedEdgeTypeGroups, setSelectedEdgeTypeGroups] = useState<EdgeTypeGroup[]>([]);
    const [selectGroupDialogVisible, setSelectGroupDialogVisible] = useState<boolean>(false);
    const selected = selectedEdgeTypes.length || selectedEdgeTypeGroups.length;

    const [saveRequired, setSaveRequired] = useState<boolean>(false);
    const currentLocale = useSelector(getCurrentLocale);

    const {
        nodeId: { serverId },
    } = serverNode;
    const presetId = preset.id;

    const intl = useIntl();
    const dispatch = useDispatch();

    const edgeTypesById = useSelector(
        EdgeTypeSelectors.byPresetId({
            serverId,
            presetId,
        }),
    );

    const edgeTypes: EdgeType[] = Object.values(edgeTypesById?.byId || {});

    const edgeTypeGroups: EdgeTypeGroup[] =
        useSelector(
            EdgeTypeGroupSelectors.byPresetIdExcludeDeleted({
                serverId,
                presetId,
            }),
        ) || [];

    const converterInitialized = (methodologiesGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(methodologiesGraph);
        }
    };

    const changeEdgeTypeGroup = useCallback(
        (changedEdgeTypes: EdgeType[]) => {
            const newEdgeTypes = edgeTypes.map((et) => changedEdgeTypes.find((e) => e.id === et.id) || et);
            dispatch(
                submitEdgeType({
                    serverNode,
                    preset,
                    edgeTypes: newEdgeTypes,
                    preventTabClose: true,
                    tabNodeId,
                }),
            );
        },
        [edgeTypes],
    );

    const { deleteMessage: deleteEdgesMessage, deleteGroupsMessage } = getMetodologyElementsDeleteMessages({
        selectedEdgeTypes,
        selectedEdgeTypeGroups,
    });

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    ExportButton.build(
                        () =>
                            dispatch(
                                exportPreset(
                                    preset,
                                    serverId,
                                    PresetElementTransferType.edge,
                                    selectedEdgeTypes.map((et) => et.id),
                                ),
                            ),
                        messages.export,
                        !(edgeTypes.length || edgeTypeGroups.length),
                        saveRequired,
                    ),
                    DeleteSelected.build(
                        () => {
                            if (selectedEdgeTypes.length) {
                                setSelectedEdgeTypes([]);
                                dispatch(deleteEdgeType({ edgeTypes: selectedEdgeTypes, serverNode }));
                            }
                            if (selectedEdgeTypeGroups.length) {
                                setSelectedEdgeTypeGroups([]);
                                dispatch(
                                    deleteEdgeTypeGroupRequest({ edgeTypeGroups: selectedEdgeTypeGroups, serverNode }),
                                );
                            }
                            setSaveRequired(true);
                        },
                        disabled || !selected,
                        undefined,
                        intl.formatMessage(messages.deleteEdgesDialogTitle),
                        <Alert
                            message={
                                <>
                                    {deleteGroupsMessage}
                                    {deleteEdgesMessage}
                                </>
                            }
                            type="warning"
                        />,
                    ),
                    MoveSelected.build(() => setSelectGroupDialogVisible(true), disabled || !selected),
                ]}
                customButtons={
                    <ButtonWithDropdown
                        title={intl.formatMessage(messages.create)}
                        itemButtons={[
                            {
                                name: messages.newEdge,
                                disabled: disabled || !edgeTypeGroups?.length,
                                onAction: () => dispatch(createEdgeType({ preset, serverNode })),
                            },
                            {
                                name: messages.newGroup,
                                disabled,
                                icon: icFolder,
                                onAction: () => dispatch(createEdgeTypeGroup({ preset, serverNode })),
                            },
                            ImportButton.build(
                                () =>
                                    dispatch(
                                        openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                                            serverNode,
                                            type: PresetElementTransferType.edge,
                                            ...importProperties,
                                        }),
                                    ),
                                messages.import,
                                disabled,
                                saveRequired,
                                icImport,
                            ),
                        ]}
                    />
                }
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={edgeTypes?.map((type) => ({ ...type, groupId: type.edgeTypeGroup?.id }))}
                typeGroups={edgeTypeGroups}
                searchFilter={searchFilter}
                icon={undefined!}
                onSelectType={setSelectedEdgeTypes as any}
                onSelectGroup={setSelectedEdgeTypeGroups as any}
                onDeleteType={(edgeType: EdgeType) => {
                    setSaveRequired(true);
                    dispatch(deleteEdgeType({ edgeTypes: [edgeType], serverNode }));
                }}
                onDeleteGroup={(edgeTypeGroup: EdgeTypeGroup) => {
                    setSaveRequired(true);
                    dispatch(
                        deleteEdgeTypeGroupRequest({
                            edgeTypeGroups: [edgeTypeGroup],
                            serverNode,
                        }),
                    );
                }}
                onEditType={(edgeType) => dispatch(editEdgeType({ serverNode, edgeType, preset }))}
                onEditGroup={(edgeTypeGroup: EdgeTypeGroup) =>
                    dispatch(editEdgeTypeGroup({ edgeTypeGroup, preset, serverNode }))
                }
                columns={[
                    {
                        title: intl.formatMessage(messages.description),
                        dataIndex: 'multilingualDescription',
                        render: (value: InternationalString) =>
                            LocalesService.internationalStringToString(value, currentLocale),
                    },
                    {
                        title: intl.formatMessage(messages.representation),
                        key: 'edgePreview',
                        render: (value: string, record: TTableRowRecord<EdgeType | EdgeTypeGroup>) => {
                            return (
                                !record.expandable && (
                                    <div data-test="edge-graph-cell">
                                        {SymbolToImageConverterGraph.convertEdge(record as EdgeType, intl, graph)}
                                    </div>
                                )
                            );
                        },
                    },
                    {
                        title: intl.formatMessage(messages.direction),
                        key: 'edgeDirection',
                        render: (edgeType: EdgeType) => {
                            if (!edgeTypeDirectionMessages[edgeType.direction]) return '';

                            return intl.formatMessage(edgeTypeDirectionMessages[edgeType.direction]);
                        },
                    },
                ]}
                actionsDisabled={disabled}
                titleDeleteMessage={messages.deleteEdgesDialogTitle}
                deleteGroupMessage={messages.deleteEdgeGroups}
                deleteElMessage={messages.deleteEdges}
            />
            <SymbolToImageConverterGraph initialized={converterInitialized} />
            {selectGroupDialogVisible && (
                <GroupSelectionDialog
                    groups={edgeTypeGroups}
                    onSubmit={(group) => {
                        if (group) {
                            changeEdgeTypeGroup(
                                selectedEdgeTypes.map((et) => ({
                                    ...et,
                                    edgeTypeGroup: group as EdgeTypeGroup,
                                })),
                            );
                        }
                        setSelectGroupDialogVisible(false);
                        setSaveRequired(true);
                    }}
                    onClose={() => setSelectGroupDialogVisible(false)}
                />
            )}
        </div>
    );
};
