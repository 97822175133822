import React, { ChangeEvent, Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Checkbox, Form, Input, Menu, Row, Col, FormInstance, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SymbolTextEditorDialog } from './Dialog/SymbolTextEditorDialog.component';
import theme from './SymbolEditor.scss';
import messages from './ObjectType.messages';
import footerMessages from '../Footer/EditorFooterButtons.messages';
import {
    InternationalString,
    ObjectType,
    Symbol,
    SymbolCreationTypeEnum,
    SymbolSettings,
} from '../../../../../../serverapi/api';
import { EditorFooterButtons } from '../Footer/EditorFooterButtons.component';
import { SelectInfo } from 'rc-menu/lib/interface';
import { TPreset } from '../../../../../../models/preset.types';
import { TreeNode } from '../../../../../../models/tree.types';
import { MxCell, MxConstants, MxPoint, MxUtils } from '../../../../../../mxgraph/mxgraph';
import { DefaultGraph } from '../../../../../../mxgraph/DefaultGraph';
import { EditorMode } from '../../../../../../models/editorMode';
import { symbolService } from '../../../../../../services/SymbolsService';
import { ObjectDefinitionImpl } from '../../../../../../models/bpm/bpm-model-impl';
import { v4 as uuid } from 'uuid';
import { insertSymbolToGraph } from '../../../../../../sagas/editor.saga.utils';
import { ApiBundle } from '../../../../../../services/api/api-bundle';
import { ColorResult, SketchPicker } from 'react-color';
import { FontFamilySelect } from '../../../../../MainMenu/components/FontFamilySelect/FontFamilySelect.component';
import { FontSizeSelect } from '../../../../../MainMenu/components/FontSizeSelect/FontSizeSelect.component';
import classnames from 'classnames';
import { Icon as UIKitIcon } from '../../../../../UIKit/components/Icon/Icon.component';
import { NUIButton } from '../../../../../UIKit/components/NUIButton/NUIButton.component';
import icBold from '../../../../../../resources/icons/ic-ribbon-text-bold.svg';
import icItalic from '../../../../../../resources/icons/ic-ribbon-text-italic.svg';
import icUnderline from '../../../../../../resources/icons/ic-ribbon-text-underline.svg';
import icLeft from '../../../../../../resources/icons/ic-ribbon-text-left.svg';
import icCenter from '../../../../../../resources/icons/ic-ribbon-text-center.svg';
import icRight from '../../../../../../resources/icons/ic-ribbon-text-right.svg';
import { TextDirectionSelect } from '../../../../../MainMenu/components/TextDirectionSelect/TextDirectionSelect.component';
import { MethodologiesGraph } from '../../../../../../mxgraph/MethodologiesGraph';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
    checkXmlParseError,
    floatToIntegerConverter,
    integerToFloatConverter,
    parseDomToXml,
    parseXmlToDom,
} from '../util/preset.utils';
import { getPointsSymbolEditorTab } from '../../../../../../mxgraph/util/PortsDefinitions.utils';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import SymbolEditorTabArrowImage from '../SymbolEditorTabArrowImage/SymbolEditorTabArrowImage.component';
import { LocalesService } from '../../../../../../services/LocalesService';
import { Locale } from '../../../../../../modules/Header/components/Header/header.types';
import symbolsTypesMessages from './SymbolsTypes.messages';
import { SymbolGeneratorDialog } from './Dialog/SymbolGenerator/SymbolGeneratorDialog.component';
import {
    SequenceSymbolTypeId,
    SymbolTypeId,
} from '../../../../../../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { ComplexSymbolManager } from '../../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { InputId } from '../../../../../InputId/InputId.component';
import { MAX_GUID_LENGTH } from '../../../../../../utils/consts';
import { InputSynonymsIds } from '../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { convertStringToArray } from '../../../../../../utils/convertStringToArray';
import { DisableLabelCheckBox } from './DisableLabelCheckBox';
import { convertToNumber } from '../../../../../../utils/ConvertToNumber.utils';

const ButtonGroup = Button.Group;

type TConstraintsPoint = {
    id: string;
    coordinatesX: string;
    coordinatesY: string;
    name: string;
};

type TSymbolEditorTabProps = {
    symbol: Symbol;
    preset: TPreset;
    serverNode: TreeNode;
    isNewSymbol: boolean;
    objectType: ObjectType;
    graphical?: string;
    currentLocale: Locale;
};

type TSymbolEditorTabActionProps = {
    onCancel: () => void;
    submitSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    createSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    openFileUploadDialog: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
};

type TState = {
    contentId: number;
    tmpSymbol: Symbol;
    id: string;
    synonymsIds?: string[];
    constraintPoints: TConstraintsPoint[];
    textEditorVisible: boolean;
    symbolGeneratorVisible: boolean;
    saveProportion?: boolean;
    cell?: MxCell;
    isDoubleConstraintPoints: boolean;
    isEmptyName: boolean;
    previousAction: previousActionEnum;
    isEditableSymbol: boolean;
};

type TSymbolEditorTabFullProps = TSymbolEditorTabProps & TSymbolEditorTabActionProps & WrappedComponentProps;

enum SymbolMenu {
    DESIGN,
    LABEL,
    POINTS,
    ICON,
}

enum previousActionEnum {
    NONE,
    ADDING,
    DELETE,
}

const tmpSymbolId = 'tmpSymbolId';
const nodeId = {
    serverId: uuid(),
    repositoryId: uuid(),
    id: uuid(),
};

const objectDefinitionImpl = new ObjectDefinitionImpl({
    nodeId,
    isDirty: true,
});

const alwaysEditableSymbolsIds: string[] = [
    SymbolTypeId.CROSS,
    SymbolTypeId.HORIZONTAL_POOL,
    SymbolTypeId.HORIZONTAL_SWIMLANE,
    SymbolTypeId.VERTICAL_POOL,
    SymbolTypeId.VERTICAL_SWIMLANE,
];

class SymbolEditorTab extends Component<TSymbolEditorTabFullProps, TState> {
    private graphRef: HTMLDivElement;
    private graph: MethodologiesGraph;
    formRef = React.createRef<FormInstance>();

    constructor(props: TSymbolEditorTabFullProps) {
        super(props);
        let { width } = props.symbol;
        let { height } = props.symbol;

        if (!(width && height)) {
            const parser = new DOMParser();
            const graphical = parser.parseFromString(
                this.props.graphical || this.props.symbol.graphical,
                'application/xml',
            );
            height = graphical.documentElement.getAttribute('h')
                ? Number(graphical.documentElement.getAttribute('h'))
                : undefined;
            width = graphical.documentElement.getAttribute('w')
                ? Number(graphical.documentElement.getAttribute('w'))
                : undefined;
        }

        this.state = {
            contentId: SymbolMenu.DESIGN,
            tmpSymbol: {
                ...props.symbol,
                width,
                height,
                id: tmpSymbolId,
                objectType: tmpSymbolId,
                graphical: this.props.graphical || this.props.symbol.graphical
            },
            textEditorVisible: false,
            symbolGeneratorVisible: false,
            constraintPoints: [],
            id: props.symbol.id,
            synonymsIds: props.symbol.synonymsIds,
            isDoubleConstraintPoints: false,
            isEmptyName: false,
            previousAction: previousActionEnum.NONE,
            isEditableSymbol: true,
        };
    }

    componentDidMount() {
        const symbol: Symbol = this.state.tmpSymbol;

        if (!this.graph) {
            const graph = new MethodologiesGraph({ container: this.graphRef });
            graph.setSymbolName(symbol.name);
            this.graph = graph;
            graph.setIntl(this.props.intl);
            graph.bpmMxGraphContext = {
                api: {} as ApiBundle,
                serverId: this.props.serverNode.nodeId.serverId,
                objectDefinitionCopyPasteContext: {},
            };
        }

        this.setEditingRulesSymbol(symbol);
        this.updateSymbol(symbol);
        this.switchToEditDesignTab();
        this.setInitialConstraintsPoints();
        this.checkSubmitEnabled();
    }

    componentDidUpdate(prevProps: Readonly<TSymbolEditorTabFullProps>, prevState: Readonly<TState>) {
        if (this.state.tmpSymbol.graphical !== prevState.tmpSymbol.graphical) {
            this.setInitialConstraintsPoints();
        }
        if (
            prevProps.graphical !== this.props.graphical &&
            prevProps.symbol.graphical !== this.props.symbol.graphical
        ) {
            const symbol = {
                ...this.state.tmpSymbol,
                graphical: this.props.graphical || this.props.symbol.graphical,
                // некоторые символы добавляются по шейпу из стилей так что для временного символа
                // тоже добавим в стили временный шейп. Для bpmn.
                // style: newProps.symbol.style?.split(';').filter(s => s.indexOf( MxConstants.STYLE_SHAPE) < 0)?.join(';')
            };

            this.updateSymbol(symbol);
            this.switchToEditDesignTab();
        }
    }

    componentWillUnmount() {
        if (this.graphRef && this.graph) {
            this.graph.destroy();
        }

        this.setState({ isDoubleConstraintPoints: false });
    }

    setEditingRulesSymbol = (symbol: Symbol) => {
        const symbolTypeId: SymbolTypeId | SequenceSymbolTypeId = ComplexSymbolManager.getSymbolType(symbol);
        const isHorizontalSwimlane: boolean = symbolTypeId === SymbolTypeId.HORIZONTAL_SWIMLANE;
        const isVerticalSwimlane: boolean = symbolTypeId === SymbolTypeId.VERTICAL_SWIMLANE;
        const isVR: boolean = symbolTypeId === SymbolTypeId.VR;
        const isLifeLine: boolean = [
            SequenceSymbolTypeId.LIFE_LINE,
            SequenceSymbolTypeId.LIFE_LINE_BOUNDERY,
            SequenceSymbolTypeId.LIFE_LINE_CONTROL,
            SequenceSymbolTypeId.LIFE_LINE_ENTITY,
            SequenceSymbolTypeId.LIFE_LINE_ACTOR,
        ].includes(symbolTypeId as SequenceSymbolTypeId);
        const isUmlMessage: boolean = symbolTypeId === SymbolTypeId.UML_MESSAGE;
        const isEditableSymbol: boolean = !(
            isHorizontalSwimlane ||
            isVerticalSwimlane ||
            isVR ||
            isLifeLine ||
            isUmlMessage
        );
        const { contentId } = this.state;

        this.setState({
            isEditableSymbol,
            contentId: isEditableSymbol ? contentId : SymbolMenu.ICON,
        });
    };

    setPointsToState = (points: TConstraintsPoint[]) => {
        this.setState({ constraintPoints: points });
    };

    private getConstraintPointsFromXml = (): TConstraintsPoint[] | undefined => {
        const { graphical } = this.state.tmpSymbol;
        const graphicalDOM: Document = parseXmlToDom(graphical);
        const constraints = Array.from(graphicalDOM.getElementsByTagName('constraint'));

        if (!constraints.length) {
            return undefined;
        }

        const points: TConstraintsPoint[] = constraints.map((constraint) => {
            const attributes: Attr[] = Array.from(constraint.attributes);
            const name = attributes[0]?.value || '';
            const x = attributes[2]?.value || '';
            const y = attributes[3]?.value || '';
            const id = attributes[4]?.value || uuid();

            return { name, coordinatesX: x, coordinatesY: y, id };
        });

        return points;
    };

    checkSubmitEnabled = () => {
        const generalForm = this.formRef.current;
        const multilingualName = generalForm?.getFieldValue('multilingualName')?.trim();

        this.setState({ isEmptyName: !multilingualName });
    };

    renderMenu = (): JSX.Element | JSX.Element[] => {
        if (!this.state.isEditableSymbol) {
            return <Menu.Item key={SymbolMenu.ICON}>{this.props.intl.formatMessage(messages.icon)}</Menu.Item>;
        }

        return [
            {
                id: SymbolMenu.DESIGN,
                icon: 'calendar',
                title: this.props.intl.formatMessage(messages.design),
            },
            {
                id: SymbolMenu.LABEL,
                icon: 'calendar',
                title: this.props.intl.formatMessage(messages.text),
            },
            {
                id: SymbolMenu.POINTS,
                icon: 'calendar',
                title: this.props.intl.formatMessage(messages.pointsOfConnection),
            },
            {
                id: SymbolMenu.ICON,
                icon: 'calendar',
                title: this.props.intl.formatMessage(messages.icon),
            },
        ].map((item) => <Menu.Item key={item.id}>{item.title}</Menu.Item>);
    };

    initGraphRef = (element: HTMLDivElement) => {
        if (!this.graphRef) {
            this.graphRef = element;
        }
    };

    handleSelect = (param: SelectInfo) => {
        const contentId = Number(param.key);

        if (contentId !== this.state.contentId) {
            this.setState({ contentId });
        }
    };

    setPointsToXml = (points: TConstraintsPoint[]) => {
        const { graphical } = this.state.tmpSymbol;
        const graphicalDOM: Document = parseXmlToDom(graphical);

        if (graphicalDOM.getElementsByTagName('connections')[0]) {
            graphicalDOM.getElementsByTagName('connections')[0].innerHTML = '';

            points.forEach((point) => {
                const constraint = `<constraint name="${point.name}" perimeter="0" x="${point.coordinatesX}" y="${point.coordinatesY}" id="${point.id}"/>`;
                graphicalDOM.getElementsByTagName('connections')[0].insertAdjacentHTML('beforeend', constraint);
            });

            this.setUpdateGraphical(parseDomToXml(graphicalDOM));
        }
    };

    setInitialConstraintsPoints = () => {
        const { graphical } = this.state.tmpSymbol;
        const isCorrectGraphical = this.checkIsCorrectGraphical(graphical);

        if (!isCorrectGraphical) {
            return;
        }

        const points = this.getConstraintPointsFromXml();

        if (points) {
            this.setPointsToState(points);
        }

        if (!points) {
            const defaultPoints = getPointsSymbolEditorTab();

            this.setPointsToXml(defaultPoints);
            this.setPointsToState(defaultPoints);
        }
    };

    deleteConstraintsPoint = (pointId: string) => {
        const points = this.getConstraintPointsFromXml();

        if (!points) {
            return;
        }

        const filteredPoints = points.filter((point) => point.id !== pointId);

        this.setPointsToXml(filteredPoints);
        this.setPointsToState(filteredPoints);
    };

    addConstraintsPoint = (constraints: TConstraintsPoint) => {
        const { coordinatesX, coordinatesY, name, id } = constraints;
        const { graphical } = this.state.tmpSymbol;
        const graphicalDOM: Document = parseXmlToDom(graphical);

        const constraint = `<constraint name="${name}" perimeter="0" x="${coordinatesX}" y="${coordinatesY}" id="${id}"/>`;

        if (graphicalDOM.getElementsByTagName('connections')[0]) {
            graphicalDOM.getElementsByTagName('connections')[0].insertAdjacentHTML('beforeend', constraint);
            this.setUpdateGraphical(parseDomToXml(graphicalDOM));
        } else {
            this.setUpdateGraphical(constraint);
        }
    };

    checkIsCorrectGraphical = (graphical: string): boolean => {
        /* На основе graphical парсер создает DOM для инициализации точек соединения.
         Проверяем, XML это, просто текст или пустая строка */
        const graphicalDOM: Document = parseXmlToDom(graphical);

        return !checkXmlParseError(graphicalDOM);
    };

    setUpdateGraphical = (graphical: string) => {
        if (this.checkIsCorrectGraphical(graphical)) {
            this.updateSymbol({
                ...this.state.tmpSymbol,
                graphical,
            });
        } else {
            this.setState((state) => {
                return { tmpSymbol: { ...state.tmpSymbol, graphical } };
            });
        }
    };

    onChangeStyle = (key: string, value: any) => {
        const labelStyle = MxUtils.setStyle(this.state.tmpSymbol?.labelStyle || '', key, value);
        const tmpSymbol = { ...this.state.tmpSymbol, labelStyle };

        this.updateSymbol(tmpSymbol);
        this.switchToEditTextTab();
    };

    checkboxChanged = (e: CheckboxChangeEvent) => {
        this.onChangeStyle(MxConstants.STYLE_NOLABEL, e.target.checked ? '1' : undefined);
    };

    getStyleValue = (key: string, style?: string) => {
        return (style || this.state.tmpSymbol?.labelStyle)
            ?.split(';')
            .find((n) => n.indexOf(key) >= 0)
            ?.split('=')[1];
    };

    switchToEditPointsTab() {
        this.switchToEditTextTab();
        this.graph?.setMode(EditorMode.Edit);
    }

    switchToEditTextTab() {
        this.graph?.getModel().beginUpdate();
        this.graph?.setMode(EditorMode.Edit);
        this.graph?.setCellsMovable(true);
        this.graph?.setCellsResizable(true);
        this.graph?.setVertexLabelsMovable(true);
        this.graph?.graphHandler?.setMoveEnabled(true);

        if (this.state.cell) {
            MxUtils.setCellStyles(this.graph.getModel(), [this.state.cell], MxConstants.STYLE_MOVABLE, 0);
            MxUtils.setCellStyles(this.graph.getModel(), [this.state.cell], MxConstants.STYLE_RESIZABLE, 0);
        }

        this.graph?.getModel().endUpdate();
    }

    unlockGraph() {
        this.graph?.setMode(EditorMode.Edit);
    }

    switchToEditDesignTab() {
        this.graph?.getModel().beginUpdate();
        this.graph?.setMode(EditorMode.Read);
        this.graph?.setCellsSelectable(false);
        this.graph?.setCellsMovable(false);
        this.graph?.getModel().endUpdate();
    }

    insertSymbol(symbol: Symbol, graph: DefaultGraph) {
        symbolService().applyStylesToGraph(graph, [symbol]);
        // enable for custom shapes like bpmn
        // MxCellRenderer.registerShape(symbol.id, new MxShape(MxStencilRegistry.getStencil(symbol.id)));

        return insertSymbolToGraph({
            point: new MxPoint(160, 85),
            symbol,
            graph,
            objectDefinitions: [{ ...objectDefinitionImpl, name: symbol.name }] as ObjectDefinitionImpl[],
        });
    }

    updateSymbol(symbol: Symbol) {
        this.unlockGraph();
        this.graph.clear();

        const geometry = ComplexSymbolManager.getSymbolPreviewGeometry(symbol, this.state.cell);
        const tmpSymbol = { ...symbol, ...geometry };
        const cell = this.insertSymbol(tmpSymbol, this.graph);

        this.setState({ tmpSymbol, cell });
    }

    private pointsTab() {
        const { intl } = this.props;
        const { constraintPoints, previousAction } = this.state;

        const newPointId: string = uuid();

        const changeConstraintPoints = (newPoint: TConstraintsPoint) => {
            this.setState((state) => ({ constraintPoints: [...state.constraintPoints, { ...newPoint }] }));
        };

        const checkDoublePoints = (newPoint: TConstraintsPoint) => {
            this.setState({
                isDoubleConstraintPoints: false,
            });

            return constraintPoints.some((point) => {
                return (
                    Number(newPoint.coordinatesX) === Number(point.coordinatesX) &&
                    Number(newPoint.coordinatesY) === Number(point.coordinatesY)
                );
            });
        };

        this.switchToEditPointsTab();

        const validateMessages = {
            required: intl.formatMessage(messages.validationPointsRequired),
            number: { range: intl.formatMessage(messages.validationPointsNumber) },
        };

        const onFinish = (values: TConstraintsPoint) => {
            const coordinatesX = integerToFloatConverter(values.coordinatesX);
            const coordinatesY = integerToFloatConverter(values.coordinatesY);
            const point = { ...values, coordinatesX, coordinatesY, id: newPointId };
            const isDouble = checkDoublePoints(point);

            if (isDouble) {
                this.setState({ isDoubleConstraintPoints: true });

                return;
            }

            changeConstraintPoints(point);

            this.addConstraintsPoint({ ...point });
        };

        const deletePointLabel = (pointId: string) => {
            this.setState((state) => ({
                constraintPoints: state.constraintPoints.filter((point) => point.id !== pointId),
            }));
            this.deleteConstraintsPoint(pointId);
        };

        const labelsPoints = this.state.constraintPoints.map((point) => {
            const showLabels = () => {
                return (
                    <div className={theme.pointsTab_label}>
                        {`${intl.formatMessage(messages.nameConstraintPoints)}: `}
                        <span>{point.name}</span>
                        {`, ${intl.formatMessage(messages.coordinatePointX)}: `}
                        <span>{floatToIntegerConverter(point.coordinatesX)}</span>
                        {`, ${intl.formatMessage(messages.coordinatePointY)}: `}
                        <span>{floatToIntegerConverter(point.coordinatesY)}</span>
                    </div>
                );
            };

            return (
                <div
                    data-test={`point_coordinates_${point.name}_container`}
                    className={theme.pointsTab_labelBlockWrapper_labelBlock}
                    key={point.id}
                >
                    <Row key={point.id} align="middle">
                        <Col span={20}>{showLabels()}</Col>
                        <Col span={4}>
                            <Button
                                data-test="delete_point_btn"
                                disabled={constraintPoints.length === 1}
                                className={theme.pointsTab_button}
                                type="primary"
                                onClick={() => {
                                    deletePointLabel(point.id);
                                    this.setState({
                                        previousAction: previousActionEnum.DELETE,
                                    });
                                }}
                                style={{ padding: 5 }}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </Button>
                        </Col>
                    </Row>
                </div>
            );
        });

        const normalizeNumberInput = (value: string) => {
            if (value === '' || value === '-') {
                return value;
            }

            const isNegative = value.startsWith('-');
            const numericValue = isNegative ? value.substring(1) : value;
            const convertedNumber = convertToNumber(numericValue);

            return isNegative ? -Number(convertedNumber) : Number(convertedNumber);
        };

        return (
            <div className={theme.pointsTab_wrapper}>
                <Form
                    name="pointsLabel"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    layout="inline"
                    className={theme.pointsTabForm}
                >
                    <Form.Item
                        name="name"
                        label={intl.formatMessage(messages.nameConstraintPoints)}
                        rules={[{ required: true }]}
                    >
                        <Input className={theme.inputName} data-test="name_dot_input" />
                    </Form.Item>
                    <Form.Item
                        name="coordinatesX"
                        label="X"
                        rules={[
                            {
                                type: 'number',
                                min: -100,
                                max: 100,
                                message: intl.formatMessage(messages.validationPointsNumber),
                            },
                            { required: true },
                        ]}
                        normalize={normalizeNumberInput}
                    >
                        <Input className={theme.inputNumber} data-test="x-coordinate-dot_input" />
                    </Form.Item>
                    <Form.Item
                        name="coordinatesY"
                        label="Y"
                        rules={[
                            {
                                type: 'number',
                                min: -100,
                                max: 100,
                                message: intl.formatMessage(messages.validationPointsNumber),
                            },
                            { required: true },
                        ]}
                        normalize={normalizeNumberInput}
                    >
                        <Input className={theme.inputNumber} data-test="y-coordinate-dot_input" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            data-test="add-new-dot_btn"
                            type="primary"
                            htmlType="submit"
                            className={theme.pointsTab_button}
                        >
                            {intl.formatMessage(messages.addButton)}
                        </Button>
                    </Form.Item>
                </Form>
                <div className={theme.pointsTab_labelBlockWrapper}>
                    {previousAction === previousActionEnum.DELETE && constraintPoints.length === 1 && (
                        <p className={theme.pointsTab_labelBlockWrapper_errorMessage}>
                            {intl.formatMessage(messages.countPointsValidation)}
                        </p>
                    )}
                    {this.state.isDoubleConstraintPoints && (
                        <p className={theme.pointsTab_labelBlockWrapper_errorMessage}>
                            {intl.formatMessage(messages.doublePointsValidation)}
                        </p>
                    )}
                    {labelsPoints}
                </div>
            </div>
        );
    }

    private labelTab() {
        this.switchToEditTextTab();

        const editLabelStyle = (labelStyle: string) => {
            const tmpSymbol = { ...this.state.tmpSymbol, labelStyle };
            this.updateSymbol(tmpSymbol);
            this.switchToEditTextTab();
        };

        const resetLabelStyle = () => {
            this.unlockGraph();
            if (this.state.cell) this.graph.removeCells([this.state.cell], true);
            this.setState((prev) => {
                return { ...prev, cell: undefined };
            });
            this.updateSymbol({ ...this.state.tmpSymbol, labelStyle: this.props.symbol.labelStyle });
            this.switchToEditTextTab();
        };

        const overlapChanged = (e: CheckboxChangeEvent) => {
            this.onChangeStyle(MxConstants.STYLE_OVERFLOW, e.target.checked ? 'overlap' : undefined);
        };

        const isOvelapChecked = this.getStyleValue(
            MxConstants.STYLE_OVERFLOW,
            this.state.tmpSymbol.labelStyle,
        )?.includes('overlap');

        const style = this.getStyleValue(MxConstants.STYLE_FONTSTYLE);
        const fontStyleValue = style ? Number(style) : 0;
        const alignValue = this.getStyleValue(MxConstants.STYLE_ALIGN);

        const fontStyleBtnsData = {
            bold: fontStyleValue === 1 || fontStyleValue === 3 || fontStyleValue === 5 || fontStyleValue === 7,
            italic: fontStyleValue === 2 || fontStyleValue === 3 || fontStyleValue === 6 || fontStyleValue === 7,
            underline: fontStyleValue === 4 || fontStyleValue === 5 || fontStyleValue === 6 || fontStyleValue === 7,
        };

        const textDirectionValue: string | undefined = this.getStyleValue(MxConstants.STYLE_HORIZONTAL);

        return (
            <div>
                <div className={theme.fullStyleEdit}>
                    <span>{this.props.intl.formatMessage(messages.labelFullStyle)}</span>
                    <br />
                    <Input
                        className={theme.inputStyle}
                        value={this.state.tmpSymbol.labelStyle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => editLabelStyle(event.target.value.trim())}
                    />
                </div>
                <div className={theme.textSettings}>
                    <div className={theme.group}>
                        <div className={theme.groupRow}>
                            <FontFamilySelect
                                onChange={(event) => {
                                    this.onChangeStyle(MxConstants.STYLE_FONTFAMILY, event);
                                }}
                                value={
                                    this.getStyleValue(MxConstants.STYLE_FONTFAMILY) || MxConstants.DEFAULT_FONTFAMILY
                                }
                                className={theme.selectFont}
                                renderNewOption={false}
                            />
                            <FontSizeSelect
                                onChange={(event) => {
                                    this.onChangeStyle(MxConstants.STYLE_FONTSIZE, event);
                                }}
                                value={this.getStyleValue(MxConstants.STYLE_FONTSIZE) || MxConstants.DEFAULT_FONTSIZE}
                                className={theme.selectFontSize}
                            />
                        </div>
                        <div className={theme.groupRow}>
                            <ButtonGroup className={theme.buttonGroup}>
                                <span
                                    className={classnames(theme.buttonWrapper, {
                                        [theme.buttonDisabled]: false,
                                    })}
                                >
                                    <NUIButton
                                        onClick={() =>
                                            this.onChangeStyle(
                                                MxConstants.STYLE_FONTSTYLE,
                                                fontStyleBtnsData.bold
                                                    ? fontStyleValue - MxConstants.FONT_BOLD
                                                    : fontStyleValue + MxConstants.FONT_BOLD,
                                            )
                                        }
                                        selected={fontStyleBtnsData.bold}
                                    >
                                        <UIKitIcon spriteSymbol={icBold} />
                                    </NUIButton>
                                </span>
                                <span
                                    className={classnames(theme.buttonWrapper, {
                                        [theme.buttonDisabled]: false,
                                    })}
                                >
                                    <NUIButton
                                        onClick={() =>
                                            this.onChangeStyle(
                                                MxConstants.STYLE_FONTSTYLE,
                                                fontStyleBtnsData.italic
                                                    ? fontStyleValue - MxConstants.FONT_ITALIC
                                                    : fontStyleValue + MxConstants.FONT_ITALIC,
                                            )
                                        }
                                        selected={fontStyleBtnsData.italic}
                                    >
                                        <UIKitIcon spriteSymbol={icItalic} />
                                    </NUIButton>
                                </span>
                                <span
                                    className={classnames(theme.buttonWrapper, {
                                        [theme.buttonDisabled]: false,
                                    })}
                                >
                                    <NUIButton
                                        onClick={() =>
                                            this.onChangeStyle(
                                                MxConstants.STYLE_FONTSTYLE,
                                                fontStyleBtnsData.underline
                                                    ? fontStyleValue - MxConstants.FONT_UNDERLINE
                                                    : fontStyleValue + MxConstants.FONT_UNDERLINE,
                                            )
                                        }
                                        selected={fontStyleBtnsData.underline}
                                    >
                                        <UIKitIcon spriteSymbol={icUnderline} />
                                    </NUIButton>
                                </span>
                            </ButtonGroup>
                            <ButtonGroup className={theme.buttonGroupAlign}>
                                <span
                                    className={classnames(theme.buttonWrapper, {
                                        [theme.buttonDisabled]: false,
                                    })}
                                >
                                    <NUIButton
                                        onClick={() =>
                                            this.onChangeStyle(MxConstants.STYLE_ALIGN, MxConstants.ALIGN_LEFT)
                                        }
                                        selected={alignValue === 'left'}
                                    >
                                        <UIKitIcon spriteSymbol={icLeft} />
                                    </NUIButton>
                                </span>
                                <span
                                    className={classnames(theme.buttonWrapper, {
                                        [theme.buttonDisabled]: false,
                                    })}
                                >
                                    <NUIButton
                                        onClick={() =>
                                            this.onChangeStyle(MxConstants.STYLE_ALIGN, MxConstants.ALIGN_CENTER)
                                        }
                                        selected={alignValue === 'center'}
                                    >
                                        <UIKitIcon spriteSymbol={icCenter} />
                                    </NUIButton>
                                </span>
                                <span
                                    className={classnames(theme.buttonWrapper, {
                                        [theme.buttonDisabled]: false,
                                    })}
                                >
                                    <NUIButton
                                        onClick={() =>
                                            this.onChangeStyle(MxConstants.STYLE_ALIGN, MxConstants.ALIGN_RIGHT)
                                        }
                                        selected={alignValue === 'right'}
                                    >
                                        <UIKitIcon spriteSymbol={icRight} />
                                    </NUIButton>
                                </span>
                            </ButtonGroup>
                        </div>
                        <div className={theme.groupRow}>
                            <TextDirectionSelect
                                value={textDirectionValue === undefined  || textDirectionValue === 'none'}
                                buttonGroupMode={false}
                                onSelect={(value: boolean) =>
                                    this.onChangeStyle(MxConstants.STYLE_HORIZONTAL, value ? 'none' : value)
                                }
                            />
                        </div>
                        <DisableLabelCheckBox
                            checkboxChanged={this.checkboxChanged}
                            checked={
                                Number(
                                    this.getStyleValue(MxConstants.STYLE_NOLABEL, this.state.tmpSymbol.labelStyle),
                                ) === 1
                            }
                        />
                        <div className={theme.groupRow}>
                            <Checkbox onChange={overlapChanged} checked={isOvelapChecked}>
                                <span>{this.props.intl.formatMessage(messages.overlapLabel)}</span>
                            </Checkbox>
                        </div>
                        <div className={theme.groupRow}>
                            <Button onClick={resetLabelStyle}>
                                <span>{this.props.intl.formatMessage(messages.reset)}</span>
                            </Button>
                        </div>
                    </div>
                    <div className={theme.group}>
                        <SketchPicker
                            color={this.getStyleValue(MxConstants.STYLE_FONTCOLOR)}
                            onChange={(color: ColorResult) =>
                                this.onChangeStyle(MxConstants.STYLE_FONTCOLOR, color.hex)
                            }
                            disableAlpha
                        />
                    </div>
                </div>
            </div>
        );
    }

    private iconTab() {
        const generateIcon = () => {
            if (this.state.cell) {
                const svg = this.graph.getSvg([this.state.cell], false, true);
                if (svg) {
                    this.updateSymbol({
                        ...this.state.tmpSymbol,
                        icon: `data:image/svg+xml;base64,${window.btoa(svg.outerHTML)}`,
                    });
                }
            }
        };

        const dropFile = (e: UploadRequestOption) => {
            if (e.file) {
                const reader = new FileReader();
                reader.readAsDataURL(e.file as File);
                reader.onloadend = () => {
                    this.updateSymbol({
                        ...this.state.tmpSymbol,
                        icon: reader.result as string,
                    });
                };
            }
        };

        return (
            <div className={theme.tabContainer}>
                <div className={theme.buttonContainer}>
                    <Button type="primary" onClick={generateIcon}>
                        <FormattedMessage {...messages.createFromSymbol} />
                    </Button>
                </div>
                <div>
                    <FormattedMessage {...messages.uploadIcon} />
                    <div className={theme.dragContainer}>
                        {
                            <Upload.Dragger
                                name="file"
                                multiple={false}
                                showUploadList={false}
                                customRequest={dropFile}
                                // accept={filters ? filters : ''}
                            >
                                <p className="ant-upload-text">
                                    <FormattedMessage {...messages.uploadText} />
                                </p>
                            </Upload.Dragger>
                        }
                    </div>
                </div>
                {!this.state.isEditableSymbol && (
                    <DisableLabelCheckBox
                        checkboxChanged={this.checkboxChanged}
                        checked={
                            Number(this.getStyleValue(MxConstants.STYLE_NOLABEL, this.state.tmpSymbol.labelStyle)) === 1
                        }
                    />
                )}
            </div>
        );
    }

    private designTab() {
        this.switchToEditDesignTab();

        const getRatio = (h?: number, w?: number) => {
            let divider = 1;
            if (w && h) {
                divider = w / h;
            }

            return divider;
        };

        const onChangeWidth = (event: ChangeEvent<HTMLInputElement>) => {
            const width = Number(event.target.value);

            this.updateSymbol({
                ...this.state.tmpSymbol,
                width,
                height:
                    (this.state.saveProportion &&
                        width / getRatio(this.state.tmpSymbol?.height, this.state.tmpSymbol?.width)) ||
                    this.state.tmpSymbol?.height,
            });

            this.switchToEditDesignTab();
        };

        const onChangeHeight = (event: ChangeEvent<HTMLInputElement>) => {
            const height = Number(event.target.value);

            this.updateSymbol({
                ...this.state.tmpSymbol,
                height,
                width:
                    (this.state.saveProportion &&
                        height / getRatio(this.state.tmpSymbol?.width, this.state.tmpSymbol?.height)) ||
                    this.state.tmpSymbol?.width,
            });

            this.switchToEditDesignTab();
        };

        const resetAllSettings = () => {
            this.updateSymbol(this.props.symbol);
            this.switchToEditDesignTab();
        };

        // Не сохраняется
        // const setSaveProportions = (event: CheckboxChangeEvent) => {
        //     const {
        //         target: { checked },
        //     } = event;
        //     this.setState({
        //         ...this.state,
        //         saveProportion: checked,
        //     });
        // };

        return (
            <div className={theme.tabContainer}>
                {this.props.symbol.graphical || this.state.tmpSymbol.creationType === 'SYMBOL_GENERATOR' ? ( // временно пока не появится поле у символа в апи
                    <div>
                        <div>
                            <span>
                                {this.state.tmpSymbol.creationType === 'SYMBOL_GENERATOR'
                                    ? this.props.intl.formatMessage(messages.createSymbolThroughGENERATOR)
                                    : this.props.intl.formatMessage(messages.createSymbolThroughSVG)}
                            </span>
                        </div>
                        <div className={theme.group}>
                            <div className={theme.groupRow}>
                                {this.props.symbol.creationType !== 'SYMBOL_GENERATOR' &&
                                this.state.tmpSymbol.creationType !== 'SYMBOL_GENERATOR' ? (
                                    <Button
                                        onClick={() =>
                                            this.props.openFileUploadDialog(
                                                this.props.preset,
                                                this.props.serverNode,
                                                this.props.symbol,
                                            )
                                        }
                                    >
                                        <span>{this.props.intl.formatMessage(messages.newDownload)}</span>
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                symbolGeneratorVisible: true,
                                            });
                                        }}
                                    >
                                        <span>{this.props.intl.formatMessage(messages.symbolGenerator)}</span>
                                    </Button>
                                )}

                                <Button onClick={resetAllSettings}>
                                    <span>{this.props.intl.formatMessage(messages.cancelAllSettings)}</span>
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            textEditorVisible: true,
                                        });
                                    }}
                                >
                                    <span>{this.props.intl.formatMessage(messages.symbolEditorButton)}</span>
                                </Button>
                            </div>
                        </div>
                        <div className={theme.designGroup}>
                            <div className={theme.groupRow}>
                                <span>{this.props.intl.formatMessage(messages.defaultGeometrySettingsTitle)}</span>
                            </div>
                            <div className={theme.groupRow}>
                                <div className={theme.geometryInput}>
                                    <span>{this.props.intl.formatMessage(messages.width)}</span>
                                    <br />
                                    <Input
                                        data-test="edit-symbol-width-input"
                                        value={this.state.tmpSymbol?.width || ''}
                                        onChange={onChangeWidth}
                                    />
                                </div>
                                {/* Не сохраняется */}
                                {/* <div>
                                    <Checkbox onChange={setSaveProportions}>
                                        <span>{this.props.intl.formatMessage(messages.symbolProportions)}</span>
                                    </Checkbox>
                                </div> */}
                            </div>
                            <div className={theme.groupRow}>
                                <div className={theme.geometryInput}>
                                    <span>{this.props.intl.formatMessage(messages.height)}</span>
                                    <br />
                                    <Input
                                        data-test="edit-symbol-height-input"
                                        value={this.state.tmpSymbol?.height || ''}
                                        onChange={onChangeHeight}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className={theme.designTitle}>
                            <span>{this.props.intl.formatMessage(messages.symbolSelectCreationType)}</span>
                        </div>
                        <div className={theme.designButtons}>
                            <div className={theme.editorButtonContainer}>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            textEditorVisible: true,
                                        });
                                    }}
                                    className={theme.editorButton}
                                >
                                    <span>{this.props.intl.formatMessage(messages.symbolEditorButton)}</span>
                                </Button>
                            </div>
                            <div className={theme.importButtonContainer}>
                                <Button
                                    onClick={() =>
                                        this.props.openFileUploadDialog(
                                            this.props.preset,
                                            this.props.serverNode,
                                            this.props.symbol,
                                        )
                                    }
                                    className={theme.importButton}
                                >
                                    <span>{this.props.intl.formatMessage(messages.importSymbolButton)}</span>
                                </Button>
                            </div>
                            <div className={theme.generatorButtonContainer}>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            symbolGeneratorVisible: true,
                                        });
                                    }}
                                    className={theme.generatorButton}
                                    data-test="symbol-editor_symbol-generator-btn"
                                >
                                    <span>{this.props.intl.formatMessage(messages.symbolGenerator)}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private navigationTitle() {
        return (
            <>
                <span className={theme.navigationTitle}>
                    {this.props.intl.formatMessage(messages.symbolBreadCrumbs, {
                        presetName: this.props.preset.name,
                        objectTypeName: this.props.objectType.name,
                        symbolName: this.props.symbol.name,
                    })}
                </span>
            </>
        );
    }

    private header() {
        const { symbolTypeId } = this.state.tmpSymbol;
        const symbolType =
            (symbolsTypesMessages[symbolTypeId || ''] &&
                this.props.intl.formatMessage(symbolsTypesMessages[symbolTypeId || ''])) ||
            '';

        const onChangeName = (value: InternationalString) => {
            const { tmpSymbol } = this.state;
            tmpSymbol.multilingualName = value;
            this.setState({
                tmpSymbol: { ...tmpSymbol, multilingualName: value },
            });
            this.checkSubmitEnabled();
            this.switchToEditDesignTab();
        };

        const onChangeId = () => {
            const generalForm = this.formRef.current;
            this.setState({ id: generalForm?.getFieldValue('id')?.trim() });
        };

        const onChangeSymbolSynonymsIds = (e: ChangeEvent<HTMLInputElement>): void => {
            const synonymsIds: string[] = convertStringToArray(e.target.value);

            this.setState({ synonymsIds });
        };

        return (
            <Form ref={this.formRef} layout="vertical">
                <div className={theme.headerInputs}>
                    <div>
                        <MultiLangInputDialog
                            placeholder={this.props.intl.formatMessage(messages.newSymbol)}
                            multiLangValue={this.state.tmpSymbol?.multilingualName}
                            onChange={onChangeName}
                            label={this.props.intl.formatMessage(messages.name)}
                            required
                            generalForm={this.formRef.current}
                            mainInputName="multilingualName"
                            data-test="symbol-name-input"
                        />
                    </div>
                    <div>
                        <InputId
                            value={this.props.symbol.id}
                            onChange={onChangeId}
                            required
                            disabled={!this.props.isNewSymbol}
                            mainInputName="id"
                        />
                    </div>
                    <div className={theme.formInputContainer}>
                        <InputSynonymsIds
                            value={this.props.symbol.synonymsIds}
                            mainInputName="synonymsIds"
                            onChange={onChangeSymbolSynonymsIds}
                        />
                    </div>
                    <div className={theme.formInputContainer}>
                        <div>
                            <Form.Item
                                className={theme.formItem}
                                label={this.props.intl.formatMessage(messages.symbolType)}
                                name="symbolType"
                                initialValue={symbolType}
                            >
                                <Input disabled readOnly />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }

    private footer() {
        const {
            id,
            synonymsIds,
            isEmptyName,
            tmpSymbol: {
                icon,
                style,
                labelStyle,
                width,
                height,
                symbolTypeId,
                graphical,
                multilingualName,
                creationType,
                symbolSettings,
            },
        } = this.state;
        const geometry = ComplexSymbolManager.getSymbolPreviewGeometry(this.state.tmpSymbol, this.state.cell);

        return (
            <EditorFooterButtons
                buttons={[
                    {
                        name: this.props.intl.formatMessage(footerMessages.cancel),
                        onAction: this.props.onCancel,
                    },
                    {
                        name: this.props.intl.formatMessage(footerMessages.save),
                        type: 'primary',
                        disabled:
                            isEmptyName ||
                            ((!width || !height) && alwaysEditableSymbolsIds.includes(symbolTypeId || '')) ||
                            !id.length ||
                            id.length > MAX_GUID_LENGTH,
                        onAction: () => {
                            const symbol = {
                                ...this.props.symbol,
                                graphical,
                                id,
                                synonymsIds,
                                multilingualName: multilingualName || this.props.symbol.multilingualName,
                                // для bpmn.
                                style: style || this.props.symbol.style,
                                labelStyle: labelStyle ?? this.props.symbol.labelStyle,
                                width: width || Number(this.props.symbol.width) || 150,
                                height: height || Number(this.props.symbol.height) || 100,
                                icon,
                                name: LocalesService.internationalStringToString(
                                    multilingualName || this.props.symbol.multilingualName,
                                    this.props.currentLocale,
                                ),
                                creationType: (creationType === 'SYMBOL_GENERATOR'
                                    ? 'SYMBOL_GENERATOR'
                                    : 'IMAGE') as SymbolCreationTypeEnum,
                                symbolSettings,
                                ...geometry,
                            };
                            if (this.props.isNewSymbol) {
                                this.props.createSymbol(this.props.preset, this.props.serverNode, symbol);
                            } else {
                                this.props.submitSymbol(this.props.preset, this.props.serverNode, symbol);
                            }
                        },
                        dataTestId: 'symbol-editor_save-btn',
                    },
                ]}
            />
        );
    }

    render() {
        return (
            <div className={theme.symbolEditorTabContainer}>
                {this.navigationTitle()}
                <div className={this.state.contentId === SymbolMenu.POINTS ? theme.pointsTabContent : theme.tabContent}>
                    {this.header()}
                    <div className={theme.symbolPresentation}>
                        <div className={theme.presentationContainer}>
                            {this.state.contentId === SymbolMenu.POINTS && (
                                <SymbolEditorTabArrowImage
                                    color="var(--blue-grey)"
                                    className={theme.presentation_arrowPoints}
                                />
                            )}
                            <div className={theme.presentation}>
                                <div className={theme.graphContainer} ref={this.initGraphRef} />
                            </div>
                            {/* {this.renderSVG()} */}
                        </div>
                        <div className={theme.symbolProperties}>
                            <div>
                                <Menu
                                    defaultSelectedKeys={[SymbolMenu.DESIGN.toString()]}
                                    mode="horizontal"
                                    onSelect={this.handleSelect}
                                >
                                    {this.renderMenu()}
                                </Menu>
                            </div>
                            <div className={theme.contentMenu}>
                                {(this.state.contentId === SymbolMenu.DESIGN && this.designTab()) ||
                                    (this.state.contentId === SymbolMenu.LABEL && this.labelTab()) ||
                                    (this.state.contentId === SymbolMenu.POINTS && this.pointsTab()) ||
                                    (this.state.contentId === SymbolMenu.ICON && this.iconTab())}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={theme.footer}>{this.footer()}</div>

                {this.state.textEditorVisible && (
                    <SymbolTextEditorDialog
                        graphical={this.state.tmpSymbol.graphical}
                        onSubmit={(graphical: string) => {
                            this.setUpdateGraphical(graphical);
                            this.setState({
                                textEditorVisible: false,
                            });
                        }}
                        onClose={() => this.setState({ textEditorVisible: false })}
                    />
                )}
                {this.state.symbolGeneratorVisible && (
                    <SymbolGeneratorDialog
                        tmpSymbol={this.state.tmpSymbol}
                        nodeId={this.props.serverNode.nodeId}
                        presetId={this.props.preset.id}
                        onClose={() => this.setState({ symbolGeneratorVisible: false })}
                        onSubmit={(graphical: string, currentStyles: SymbolSettings) => {
                            this.setUpdateGraphical(graphical);
                            this.updateSymbol({
                                ...this.state.tmpSymbol,
                                graphical,
                                width: currentStyles.symbolSettings?.width,
                                height: currentStyles.symbolSettings?.height,
                                creationType: 'SYMBOL_GENERATOR',
                                symbolSettings: currentStyles,
                            });
                            this.setState({
                                symbolGeneratorVisible: false,
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

const SymbolEditorTabIntl = injectIntl(SymbolEditorTab);

export { SymbolEditorTabIntl as SymbolEditorTab };
