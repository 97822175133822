import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import icLeft from 'icons/toolbar/controls/ic-text-align-left.svg';
import icCenter from 'icons/toolbar/controls/ic-text-align-center.svg';
import icRight from 'icons/toolbar/controls/ic-text-align-right.svg';
import { useIntl } from 'react-intl';
import icAlignFill from 'icons/toolbar/controls/ic-text-align-fill.svg';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import messages from '../CommonToolbar.messages';
import { Dropdown } from '../../../Toolbar/Dropdown.component';

const getFocusedBlock = (editor: Editor) =>
    (editor.isActive({ textAlign: 'left' }) && 'left') ||
    (editor.isActive({ textAlign: 'right' }) && 'right') ||
    (editor.isActive({ textAlign: 'center' }) && 'center') ||
    (editor.isActive({ textAlign: 'justify' }) && 'justify') ||
    '';

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setTextAlign(value).run();

export const TextAlignComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const intl = useIntl();

    const onChange = (value: string) => {
        setCurrentState(value);
    };

    const menuItems = [
        {
            value: 'left',
            tooltip: intl.formatMessage(messages.textLeft),
            spriteSymbol: icLeft,
            selected: value === 'left',
            dataTest: 'wiki-toolbar-group_font-align-button_group_left',
        },
        {
            value: 'center',
            tooltip: intl.formatMessage(messages.textCenter),
            spriteSymbol: icCenter,
            selected: value === 'center',
            dataTest: 'wiki-toolbar-group_font-align-button_group_center',
        },
        {
            value: 'right',
            tooltip: intl.formatMessage(messages.textRight),
            spriteSymbol: icRight,
            selected: value === 'right',
            dataTest: 'wiki-toolbar-group_font-align-button_group_right',
        },
        {
            value: 'justify',
            tooltip: intl.formatMessage(messages.textJustify),
            spriteSymbol: icAlignFill,
            selected: value === 'justify',
            dataTest: 'wiki-toolbar-group_font-align-button_group_justify',
        },
    ];

    return (
        <Dropdown<string>
            value={value}
            items={menuItems}
            title={intl.formatMessage(messages.textAlign)}
            compact={true}
            defaultSprite={icLeft}
            onSelect={onChange}
            dataTest="wiki-toolbar-group_font-align-button_group_popover"
        />
    );
};
