import type { Editor } from '@tiptap/react';
import { getNodeAtPosition } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import { TablePicker, TTablePickerSize } from 'UIKit';
import icTable from 'icons/toolbar/controls/ic-table.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { isTableSelected } from '../../common/helpers';
import { Dropdown } from '../../../Toolbar/Dropdown.component';

const safeInsertTable = (editor: Editor, { rows, cols }: TTablePickerSize) => {
    const startPos = 0;
    const endPos = editor.state.doc.content.size;

    return editor
        .chain()
        .focus()
        .command(({ state, chain }) => {
            const currentPos = state.selection.$anchor.pos;
            const isStartPositionSelected = startPos + 1 === currentPos;
            const isEndPositionSelected = endPos - 1 === currentPos;
            const hasTableOnBack =
                currentPos - 2 < startPos ? false : !!getNodeAtPosition(state, 'table', currentPos - 2)[0];
            const hasTableInFront =
                currentPos + 2 > endPos ? false : !!getNodeAtPosition(state, 'table', currentPos + 2)[0];

            if (isStartPositionSelected || hasTableOnBack) {
                chain().enter().run();
            }

            if (isEndPositionSelected || hasTableInFront) {
                chain().enter().selectNodeBackward().run();
            }

            return true;
        })
        .insertTable({ rows, cols })
        .run();
};

const getChangedBlock = (editor: Editor, { rows, cols }: TTablePickerSize) =>
    !isTableSelected(editor) && safeInsertTable(editor, { rows, cols });

export const TableComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TTablePickerSize>({ stateObserver, getChangedBlock });
    const onChangeHandler = (tableSize: TTablePickerSize) => {
        setCurrentState(tableSize);
    };

    return (
        <Dropdown<string>
            overlay={<TablePicker onChange={onChangeHandler} />}
            title={intl.formatMessage(messages.insertTable)}
            arrow={false}
            compact={true}
            defaultSprite={icTable}
            dataTest="wiki-toolbar-group_add-table-button"
        />
    );
};
