import { Node, getTextBetween, mergeAttributes } from '@tiptap/core';
import { CommentTooltipPlugin } from '../plugins/comment/CommentTooltip.plugin';
import React from 'react';

type TCommentOptions = {
    types: string[];
    HTMLAttributes: Record<string, any>;
    tooltipContainerName?: string;
    tooltipDelay?: number;
    commentTooltipComponent: React.ElementType;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        comment: {
            /**
             * Set a comment mark
             */
            setComment: (threadId: string, commentId: string) => ReturnType;
            /**
             * Unset a comment mark
             */
            unsetComment: () => ReturnType;
        };
    }
}

export const Comment = Node.create<TCommentOptions>({
    name: 'comment',

    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,

    // TODO
    // @ts-ignore
    addOptions() {
        return {
            // TODO другие типы
            types: ['paragraph'],
            HTMLAttributes: {},
            tooltipDelay: 500,
            tooltipContainerName: 'commentTextAreaContainer',
        };
    },

    addAttributes() {
        return {
            threadId: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('thread-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.threadId) {
                        return {};
                    }

                    return {
                        'thread-id': attributes.threadId,
                    };
                },
            },
            commentId: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('comment-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.commentId) {
                        return {};
                    }

                    return {
                        'comment-id': attributes.commentId,
                    };
                },
            },
            commentLabel: {
                default: null,
                parseHTML: (element) => element.getAttribute('comment-label'),
                renderHTML: (attributes) => {
                    if (!attributes.commentLabel) {
                        return {};
                    }

                    return {
                        'comment-label': attributes.commentLabel,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[comment-id]',
            },
        ];
    },

    renderText({ node }) {
        return node.attrs.commentLabel;
    },

    renderHTML({ node, HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), node.attrs.commentLabel];
    },

    addCommands() {
        return {
            setComment:
                (threadId, commentId) =>
                ({ state, chain }) => {
                    const attributes = { threadId, commentId };
                    const { $from: from, $to: to } = state.selection;
                    const range = { from: from.pos, to: to.pos };
                    const currentText = getTextBetween(state.doc, range);

                    return chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: this.name,
                                attrs: { ...attributes, commentLabel: currentText },
                            },
                        ])
                        .run();
                },
            unsetComment:
                () =>
                ({ commands }) => {
                    return this.options.types.every((type) => commands.resetAttributes(type, this.name));
                },
        };
    },

    addProseMirrorPlugins() {
        return [
            // TODO использовать default values в типизации
            CommentTooltipPlugin({
                delay: this.options.tooltipDelay || 500,
                commentNodeName: this.name,
                tooltipContainerName: this.options.tooltipContainerName || 'commentTextAreaContainer',
                commentTooltipComponent: this.options.commentTooltipComponent,
            }),
        ];
    },
});
