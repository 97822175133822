import React, { FC, useRef } from 'react';
import theme from './NewMatrixEditor.scss';
import cn from 'classnames';
import { Table } from 'semantic-ui-react';
import { TSelectedHeadersCells } from '@/reducers/entities/newMatrix.reducer.types';
import { MatrixCellBPM8764, MatrixDataBPM8764, MatrixLane, NodeId } from '@/serverapi/api';
import { fillCellsWithSameLinkedNodeIds, renderCellSymbol } from '../utils';
import { matrixClearSelectedCells, matrixSelectCells } from '@/actions/entities/newMatrix.actions';
import { useDispatch } from 'react-redux';
import { NewMatrixContextMenu } from './NewMatrixContextMenu/NewMatrixContextMenu.component';
import { useContextMenuDublicateDelete } from '@/hooks/useContextMenuDublicateDelete';
import { MenuInfo } from 'rc-menu/es/interface';
import messages from './NewMatrixContextMenu/NewMatrixContextMenu.messages';
import { ContextActions } from './NewMatrix.types';
import { getMenuItem } from '@/utils/antdMenuItem.utils';
import { useIntl } from 'react-intl';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { Tooltip } from 'antd';
import { LocalesService } from '@/services/LocalesService';

type TNewMatrixCellProps = {
    nodeId: NodeId;
    cellId: string;
    rowId: string;
    colId: string;
    rowIndex: number;
    colIndex: number;
    isReadMode: boolean;
    isCellSelectable: boolean;
    selectedCells: string[];
    cell: MatrixCellBPM8764;
    selectedHeaderCells: TSelectedHeadersCells;
    rowsHeaders: MatrixLane[];
    colsHeaders: MatrixLane[];
    matrixData: MatrixDataBPM8764;
    currentLocale: Locale;
    updateMatrixData: (data: MatrixDataBPM8764) => void;
};

export const NewMatrixCell: FC<TNewMatrixCellProps> = ({
    nodeId,
    cellId,
    colId,
    rowId,
    cell,
    colIndex,
    rowIndex,
    isReadMode,
    isCellSelectable,
    colsHeaders,
    rowsHeaders,
    selectedCells,
    selectedHeaderCells,
    matrixData,
    currentLocale,
    updateMatrixData,
}) => {
    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete('matrix', {
        ...nodeId,
        id: cellId,
    });
    const cellClickTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const intl = useIntl();
    const dispatch = useDispatch();

    const clearCellsSelectionAction = () => {
        dispatch(matrixClearSelectedCells(nodeId));
    };

    const selectCellsAction = (ids: string[]) => {
        dispatch(matrixSelectCells(nodeId, ids));
    };

    const hideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const isIdSelected = (id: string) => {
        return selectedCells.includes(id);
    };

    const onCellDoubleClickHandler = () => {
        if (!matrixData?.cellSettings.styles) return;

        const styles = matrixData.cellSettings.styles;

        if (!cell) return;

        if (cell.styleIds.length === 0) {
            cell.styleIds = [styles[0].id];
        } else {
            const currentStyleIndex = styles.findIndex((style) => style.id === cell.styleIds[0]);
            let newStyleIndex = currentStyleIndex + 1;
            if (cell.styleIds.length > 1 || newStyleIndex > styles.length - 1) {
                cell.styleIds = [];
            } else {
                const newStyleId = styles[newStyleIndex].id;
                cell.styleIds = [newStyleId];
            }
        }

        fillCellsWithSameLinkedNodeIds(cell, matrixData);

        updateMatrixData(matrixData);
    };

    const cellSelectHandlet = () => {
        selectCellsAction([cellId]);
    };

    const onCellClickHandler = (event: React.MouseEvent) => {
        if (isReadMode) return;

        if (selectedHeaderCells.ids.length !== 0) {
            clearCellsSelectionAction();
        }

        cellSelectHandlet();

        if (cellClickTimer.current) clearTimeout(cellClickTimer.current);

        if (event.detail === 1) {
            cellClickTimer.current = setTimeout(() => {}, 200);
        } else if (event.detail === 2) {
            onCellDoubleClickHandler();
        }
    };

    const getRenderSymbolForCell = () => {
        if (!matrixData?.cellSettings?.styles || matrixData.cellSettings.styles.length === 0) return;

        const cellStyles = matrixData.cellSettings.styles.filter((style) => cell.styleIds.includes(style.id));

        return (
            <div className={theme.cellSymbolContainer}>
                {cellStyles.map((style) => {
                    const { symbol, color, description } = renderCellSymbol(style, theme.antdIconsContainer);
                    if (cell.styleIds.length === 1 && description) {
                        const localeDescription = LocalesService.internationalStringToString(
                            description,
                            currentLocale,
                        );
                        return (
                            <Tooltip key={`${cellId}_${style.id}`} title={localeDescription}>
                                <div style={{ color }}>{symbol}</div>
                            </Tooltip>
                        );
                    }
                    return (
                        <div key={`${cellId}_${style.id}`} style={{ color }}>
                            {symbol}
                        </div>
                    );
                })}
            </div>
        );
    };

    const openContextMenu = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        event.preventDefault();

        if (isCellSelectable) {
            cellSelectHandlet();
            setContextMenuVisible(true);
        }
    };

    const contextMenuItems: ItemType[] = [
        getMenuItem(
            intl.formatMessage(messages[ContextActions.symbolsSettings]),
            ContextActions.symbolsSettings,
            !isCellSelectable,
        ),
    ];

    const handleMenuAction = (event: MenuInfo) => {
        switch (event.key) {
            case ContextActions.symbolsSettings:
                dispatch(
                    openDialog(DialogType.NEW_MATRIX_CELL_SYMBOLS_SETTINGS_DIALOG, {
                        nodeId,
                        selectedStyleIds: cell.styleIds,
                        cellId,
                    }),
                );
                break;
        }
    };

    return (
        <Table.Cell
            onClick={(event: React.MouseEvent) => {
                if (isCellSelectable) onCellClickHandler(event);
            }}
            onContextMenu={openContextMenu}
            key={cellId}
            className={cn(theme.tableCell, {
                [theme.selected]:
                    selectedHeaderCells.ids.some((cellId) => cellId === rowId || cellId === colId) ||
                    isIdSelected(cellId),
                [theme.bottomBorder]:
                    rowsHeaders.findLastIndex((row) => row.text) === rowIndex &&
                    colsHeaders.findLastIndex((col) => col.text) >= colIndex,
                [theme.rightBorder]:
                    rowsHeaders.findLastIndex((row) => row.text) >= rowIndex &&
                    colsHeaders.findLastIndex((col) => col.text) === colIndex,
                [theme.pointer]: isCellSelectable,
            })}
        >
            {isContextMenuVisible && (
                <NewMatrixContextMenu
                    visible={isContextMenuVisible}
                    menuItems={contextMenuItems}
                    hideContextMenu={hideContextMenu}
                    handleMenuAction={handleMenuAction}
                />
            )}
            {getRenderSymbolForCell()}
        </Table.Cell>
    );
};
